import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import {
  getData,
  postData,
  putData,
  deleteData,
  getDownloadDataExcel,
} from "./api";
import { Per_Page_Dropdown, Select2Data, RegxExpression } from "./common";
import { getCart } from "./apis/addToCart/addToCart";
import { getWishlist } from "./apis/Wishlist/Wishlist";
import { formatDate } from "./apis/common/Common";
export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://nodemittalcraftworks.hostelbliss.com:3041";
  // const IMG_URL = "http://netpurtinode.profcymabackend.com:3434"
  const IMG_URL = "http://coinzkart-node.profcymabackend.com:3040";
  //  const IMG_URL = "http://127.0.0.1:3040"
  //  const SellerURL = "http://localhost:3000/advanceDashboard"
  const SellerURL =
    "http://coinzkart-admin.profcymabackend.com/advanceDashboard";
    // const SellerURL =
    // "http://adminmittalcraftworks.hostelbliss.com/advanceDashboard";
  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  const [signinGoogle, setSigninGoogle] = useState(false);
  // const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [productName, setProductName] = useState("");
  const [variant, setVariant] = useState("");
  const [userdata, setUserData] = useState({});
  const [isAllow, setisAllow] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const [cartwithoutData, setcartwithoutData] = useState([]);
  const [addCartModal, setAddCartModal] = useState(false);
  const [shimmerLoader, setShimmerLoader] = useState(false);
  const [isCookieAccepted, setIsCookieAccepted] = useState(false);
  const minLength = 2;
  const maxLength = 30;

  useEffect(() => {
    isTokenValid();
  }, [signin]);
 
  const [cartCount, setCartCount] = useState(0);
  const [cartData, setCartData] = useState();
  const [trigger, setTrigger] = useState(0);

  const triggerCartUpdate = () => {
    setTrigger(trigger === 0 ? 1 : 0);
  };
 

  const updateCartCount = async () => {
    if (signin) {
      try {
        const cartItems = await getData(`/cart/show`);
        setCartCount(cartItems?.data?.length || 0);
      } catch (error) {
        console.log(error);
      }
    } else if (Cookies.get("coinz_kart_cart")) {
      const cartItems = JSON.parse(Cookies.get("coinz_kart_cart"));

      // Process cart items to include sizesLength
      const totalSizesLength = cartItems.reduce((acc, product) => {
        const validSizes = product.sizes.filter((size) => size.quantity > 0);
        return acc + (validSizes.length > 0 ? validSizes.length : 1);
      }, 0);

      setCartCount(totalSizesLength);
    }
  };

  useEffect(() => {
    updateCartCount();
  }, [Cookies.get("coinz_kart_cart"), signin, trigger, addCartModal]);

  

  const isTokenValid = async () => {
    const token = Cookies.get("coinz_kart_security");

    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("coinz_kart_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        setisAllow([]);
        navigate("/");
      } else {
        setisAllow(decodedToken?.permissions);
        // getuserData(decodedToken.user);
        setUsertype(decodedToken.rolename);
        const cart = await getCart();
        await setCartCount(cart?.data?.length);
        await setCartData(cart);
        const wishlist = await getWishlist();
        await setWishlistData(wishlist?.data);
        await setSignin(true);
      }
    } else {
      setisAllow([]);
      await setSignin(false);
      setUsertype("");
      // navigate("/");
    }
  };

  const wishlistContext = async () => {
    if (signin) {
      const res = await getWishlist();
      if (res?.success) {
        setWishlistData(res?.data);
      
      }
    } else {
      setWishlistData([]);
    }
  };

  const SearchProductName = async () => {
    const res = await getWishlist();
    if (res?.success) {
      setWishlistData(res?.data);
    
    }
  };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };


  useEffect(() => {
    // Check if cookies have already been accepted
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (cookieAccepted) {
      setIsCookieAccepted(true);
    }
  }, []);

 
  

  useEffect(() => {
   
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      const user = urlParams.get('user');
    console.log(token,"token token token");
    
      if (token && user) {
        Cookies.set("coinz_kart_security", token, {
          expires: 1,
         
      });
      setSignin(true);
        // Display the user information in the frontend
        console.log('User:', user);
      } else {
        console.log('Login failed or missing token');
      }
   

}, [signinGoogle]);

  return (
    <Context.Provider
      value={{
        SellerURL,
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        ErrorNotify,
        variant,
        setVariant,
        // token,
        // setToken,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        Select2Data,
        Per_Page_Dropdown,
        RegxExpression,
        shimmerLoader,
        setShimmerLoader,
        addCartModal,
        setAddCartModal,
        cartwithoutData,
        setcartwithoutData,
        cartCount,
        triggerCartUpdate,
        wishlistData,
        setWishlistData,
        wishlistContext,
        updateCartCount,
        formatDate,
        productName,
        setProductName,
        setIsCookieAccepted,
        isCookieAccepted,
        cartData,
        signinGoogle,
        setSigninGoogle,
      }}
    >
      {children}
    </Context.Provider>

  );
};

export default AppContext;
