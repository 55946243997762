import React, { useEffect, useRef, useState, useContext } from 'react'
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { Row } from "react-bootstrap";
import ProductCard from '../Common-Component/ProductCard/ProductCard';
import "./BestMatched.css";
import { Products } from "../../utils/apis/Product/Product";
const BestMatched = ({category}) => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [swiperInstancenew, setSwiperInstancenew] = useState(null);
    const orders = [
        {
            id: 1,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'Nike Dri-FIT Legend Tee - Men\'s',
            price: '0000'
        },
        {
            id: 2,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'White Coffee Mug',
            price: '0000'
        },
        {
            id: 3,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            price: '0000'
        },
        {
            id: 4,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            price: '0000'
        }

    ]; 
    const [productdata, setproductData] = useState();
    const getAllData = async () => {
      
      
         const  res = await Products(1,category);
        
        if (res?.success) {
            setproductData(res?.data?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    useEffect(() => {
        getAllData();
    }, []); 
    return (
        <>
            <section className='BestMatchedMain'>
                <div className='BestMatched'>
                    <div className='bestMatchedhead'>
                        <h3>Best Matched With</h3>
                    </div>
                    <Row>
                        <Swiper
                            slidesPerView={"auto"}
                            autoplay={{
                                delay: 2000, // 3 seconds delay between slides
                                disableOnInteraction: false, // allows autoplay to continue after interactions
                              }}
                              modules={[Pagination, Autoplay]}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                            breakpoints={{
                                0: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                370: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                485: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                575: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },

                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1200: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1440: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                2000: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                            }}
                            navigation
                            pagination={{ clickable: true }}
                            className="mySwiper1"
                        >

                            {productdata?.map(order => (
                                <SwiperSlide>
                                    <ProductCard order={order} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Row>
                </div>

            </section>
        </>
    )
}

export default BestMatched