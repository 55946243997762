import React, { useEffect, useRef, useState, useContext } from "react";
import "./CustomizableProduct.css";
import { Col, Row } from "react-bootstrap";
import ProductCard from "../Common-Component/ProductCard/ProductCard";
import { NavDropdown } from "react-bootstrap";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Form, Accordion } from "react-bootstrap";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Paginationss from "@mui/material/Pagination";
import FilterOffcanvas from "../Common-Component/Offcanvas/FilterOffcanvas/FilterOffcanvas";
import {
    Brands,
    Material,
    Occasion,
    priceRange,
    allCategory,
} from "../../utils/apis/master/Master";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Context } from "../../utils/context";
import { useForm, Controller } from "react-hook-form";
import { Products } from "../../utils/apis/Product/Product";
import Pagination from 'react-bootstrap/Pagination';
import EmptyAnimation from "../EmptyAnimation/EmptyAnimation";
import { useLocation } from 'react-router-dom';
const CustomizableProduct = ({ heading, sustainable, promotional, custom }) => {
   
    const { id } = useParams();
    const { occationId } = useParams();

    useEffect(() => {
        setOccasionId(occationId);
    }, [occationId]);

    const location = useLocation();
    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
  
    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const min = queryParams.get('minprice');
      const max = queryParams.get('maxprice');
  
      if (min) setMinPrice(parseFloat(min));
      if (max) setMaxPrice(parseFloat(max));
    }, [location.search]);

    const { categoryId } = useParams();
    const [price, setPrice] = useState({ minPrice: 0, maxPrice: 100 });
    const [value, setValue] = useState({ min: 0, max: 100 });
    
    useEffect(() => {
        if (minPrice && maxPrice) {
            setValue({ min: parseInt(minPrice, 10), max: parseInt(maxPrice, 10) });
        }
    }, [minPrice, maxPrice, setValue]);

    const getAllPrice = async () => {
        const res = await priceRange();
        if (res?.success) {
            setPrice(res?.data);
        } else {
            console.error("Error fetching data:", res?.error);
        }
    };

    useEffect(() => {
        getAllPrice();
    }, []);
    useEffect(() => {
        if (price) {
            // Ensure minPrice is not greater than maxPrice
            const minPrice = Math.min(price.minPrice, price.maxPrice);
            const maxPrice = Math.max(price.minPrice, price.maxPrice);

            setValue({ min: minPrice, max: maxPrice });
        }
    }, [price]);


    const handleChange = (newValue) => {
        let { min, max } = newValue;

        // Ensure the difference between max and min is at least 10
        const minDifference = 1000;
        if (max - min < minDifference) {
            // Adjust max to maintain the minimum difference if less than required
            max = min + minDifference;
        }

        // Prevent overlap by ensuring max is greater than min
        if (min > max) {
            min = max - minDifference;
        }

        // Ensure values are within the provided range
        min = Math.max(price.minPrice, Math.min(min, price.maxPrice - minDifference));
        max = Math.min(price.maxPrice, Math.max(max, min + minDifference));

        setValue({ min, max });
    };
    // const handleChange = (newValue) => {
    //     setValue(newValue);
    // };
    // useEffect(() => {
    //     if (price) {
    //         setValue({ min: price.minPrice, max: price.maxPrice });
    //     }
    // }, [price]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm();
    const [activePage, setActivePage] = useState(1);
    const [brandId, setBrandId] = useState(null);
    const [CategoryId, setCategoryId] = useState(null);
    const [occsionId, setOccasionId] = useState(null);
    const [materialId, setmaterialId] = useState(null);

    const [isActiveBrand, setIsActiveBrand] = useState(false);
    const [isActiveOccasion, setIsActiveOccasion] = useState(false);
    const [isActiveMaterial, setIsActiveMaterial] = useState(false);
    const [isActiveCategory, setIsActiveCategory] = useState(false);

    const handleClickOccasion = (id) => {
        setOccasionId(id);
        setIsActiveOccasion(id); // Toggle the active state
    };

    const handleClickBrand = (id) => {
        setBrandId(id);
        setIsActiveBrand(id); // Toggle the active state
    };

    const handleClickMaterial = (id) => {
        setmaterialId(id);
        setIsActiveMaterial(id); // Toggle the active state
    };

    const handleClickCategory = (id) => {
        setCategoryId(id);
        setIsActiveCategory(id); // Toggle the active state
    };

  

    useEffect(() => {
        setCategoryId(categoryId);
    }, [categoryId]);

    const { IMG_URL, productName, setProductName } = useContext(Context);
    const [brand, setBrand] = useState();
    const getAllBrand = async () => {
        const res = await Brands();
        if (res?.success) {
            setBrand(res?.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };
    const [occasion, setOccasion] = useState();
    const getAllOccasion = async () => {
        const res = await Occasion();
        if (res?.success) {
            setOccasion(res?.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };
    const [category, setcategory] = useState();
    const getAllCategory = async () => {
        const res = await allCategory(2);
        if (res?.success) {
            setcategory(res?.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };
    const [material, setmaterial] = useState();
    const getAllmaterial = async () => {
        const res = await Material();
        if (res?.success) {
            setmaterial(res?.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };

    const [quantity, setQuantity] = useState("");
    const [noMinQty, setNoMinQty] = useState(false);

    // Handle input change
    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        setNoMinQty(event.target.checked);
    };
    useEffect(() => {
        getAllCategory();
        getAllBrand();
        getAllOccasion();
        getAllmaterial();
        getAllPrice();
    }, []);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const [totalEntries, setTotalEntries] = useState(0);
    const [productdata, setproductData] = useState();
    const [sortOption, setSortOption] = useState("");

    console.log(occsionId,'occationId occationId');
    
    const getAllData = async () => {
     
        let res;

        res = await Products(
            activePage,
            CategoryId || "",
            id || "",
            "",
            "",
            brandId || "",
            materialId || "",
            "",
            value || "",
            sustainable || "",
            promotional || "",
            "",
            productName || "",
            occsionId || "",
            custom || "",
            quantity || "",
            noMinQty || "",
            sortOption || ""
        );
        if (res?.success) {
            setproductData(res?.data);
            setCurrentPage(res?.data?.currentPage);
            setTotalPages(res?.data?.totalPages);
            setPerPage(res?.data?.per_page);
            setTotalEntries(res?.data?.totalEntries);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };
    useEffect(() => {
        getAllData();
    }, [
        brandId,
        occsionId,
        materialId,
        value,
        occsionId,
        id,
        CategoryId,
        quantity,
        noMinQty,
        productName,
        sortOption,
        activePage,
        maxPrice,
        minPrice,
    ]);


    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };
    const paginationItems = [];
    for (let page = 1; page <= totalPages; page++) {
        paginationItems.push(
            <Pagination.Item
                key={page}
                active={page === activePage}
                onClick={() => handlePageChange(page)}
            >
                {page}
            </Pagination.Item>
        );
    }

    const items = [
        { id: 1, text: "HOME", link: "/home" },
        { id: 1, text: "PRODUCTS" },
    ];

   
    
    return (
        <>
            <section className="CustomizableProduct Blogs ">
                <div className="container">
                    <Row>
                        <Col xl={6} lg={6} md={6} xs={12}>
                            <Breadcrumbs items={items} />
                        </Col>
                        <Col xl={6} lg={6} md={6} xs={12}>
                            <div className="sortingsec">
                                <NavDropdown
                                    title={
                                        <>
                                            <p>
                                                SORT BY{" "}
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assest/image/Careers/bars.png"
                                                    }
                                                    className="barsImage"
                                                />
                                            </p>
                                        </>
                                    }
                                    id="basic-nav-dropdown"
                                >
                                    <NavDropdown.Item
                                        as="button"
                                        onClick={() => setSortOption("PriceAscending")}
                                    >
                                        Price Ascending
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as="button"
                                        onClick={() => setSortOption("PriceDescending")}
                                    >
                                        Price Descending
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as="button"
                                        onClick={() => setSortOption("NewArrivals")}
                                    >
                                        New Arrivals
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as="button"
                                        onClick={() => setSortOption("PromotionalProduct")}
                                    >
                                        Promotional Product
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as="button"
                                        onClick={() => setSortOption("SustainableProduct")}
                                    >
                                        Sustainable Products
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6} xs={9}>
                            <div className="custoizablehead ">
                                <h3>
                                    {heading ||
                                        productdata?.data[0]?.sub_category?.name ||
                                        "Product"}
                                </h3>
                            </div>
                        </Col>
                        <Col xl={6} xs={3}>
                            <div className="filtericonmain text-end">
                                <FontAwesomeIcon
                                    icon="fa-solid fa-filter"
                                    onClick={handleShow}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={3} md={4}>
                            <div className="sidebar">
                                <Form>
                                    {/* <Form.Group controlId="customizable">
                                        <Form.Check type="checkbox" label="Customizable (514)" />
                                    </Form.Group> */}
                                    {/* <Form.Group controlId="freeShipping">
                                        <Form.Check type="checkbox" label="Free Shipping" />
                                    </Form.Group> */}

                                    <div className="productsBycat formbottomline">
                                        <h3 className="productsBycatHead">Products by Category</h3>

                                        <div className="categoryItemssec">
                                            {category?.map(
                                                (val, index) => (
                                                    <div className="categoryItem" onClick={() => handleClickCategory(val.id)}>
                                                        <p className={isActiveCategory === val.id ? "active" : ""}
                                                        >
                                                            {val?.name}
                                                        </p>
                                                        <p className="lessthanicon">
                                                            <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                                                        </p>
                                                    </div>
                                                ) // <p onClick={() => setBrandId(val.id)}>{val?.name}</p>
                                            )}

                                            {/* <div className='categoryItem'>
                                                <p>T-shirts</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div>
                                            <div className='categoryItem'>
                                                <p>Copperware</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div>

                                            <div className='categoryItem'>
                                                <p>Frames</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="price-range">
                                        <h3 className="productsBycatHead">Price Range</h3>
                                        <InputRange
                                            maxValue={Number(price?.maxPrice) || 100}
                                            minValue={Number(price?.minPrice) || 0}
                                            value={value}
                                            onChange={handleChange}
                                        />

                                    </div>

                                    <Form.Group controlId="quantity">
                                        <h3 className="productsBycatHead">Quantity</h3>
                                        <div className="quantityform">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Quantity"
                                                value={quantity}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;

                                                    // Allow only numbers and limit the length to 8 characters
                                                    if (/^\d{0,8}$/.test(inputValue)) {
                                                        setQuantity(inputValue);
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    // Prevent non-numeric characters except for control keys like Backspace, Delete, Arrow keys, etc.
                                                    const allowedKeys = [
                                                        'Backspace',
                                                        'Delete',
                                                        'ArrowLeft',
                                                        'ArrowRight',
                                                        'Tab',
                                                    ];

                                                    if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                className={`chartinfo mb-0 ${noMinQty && !noMinQty ? 'is-invalid' : ''}`}
                                                required={noMinQty ? true : false}  // Conditionally set the field as required
                                            // style={{
                                            //     borderColor: noMinQty && !quantity ? 'red' : '', // Set border color based on condition
                                            //     borderWidth: '1px',
                                            //     borderStyle: 'solid'
                                            // }}
                                            />


                                            <div className="onfieldtxt">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="No min qty"
                                                    checked={noMinQty}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </div>
                                        </div>
                                    </Form.Group>


                                    <Accordion>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Brand</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="accordBodycontent">
                                                    {brand?.map((val, index) => (
                                                        <p
                                                            className={isActiveBrand === val.id ? "active" : ""}
                                                            onClick={() => handleClickBrand(val.id)}
                                                        >
                                                            {val?.name}
                                                        </p>
                                                    ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>



                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>Occasion</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="accordBodycontent">
                                                    {occasion?.map((val, index) => (
                                                        <p
                                                            className={isActiveOccasion === val.id ? "active" : ""}
                                                            onClick={() => handleClickOccasion(val?.id)}
                                                        >
                                                            {val?.name}
                                                        </p>
                                                    ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>Material</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="accordBodycontent">
                                                    {material?.map((val, index) => (
                                                        <p
                                                            className={isActiveMaterial === val.id ? "active" : ""}
                                                            onClick={() => handleClickMaterial(val.id)}
                                                        >
                                                            {val?.name}
                                                        </p>
                                                    ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>


                                    </Accordion>
                                </Form>
                            </div>
                        </Col>

                        <Col lg={9} md={8}>
                            <Row>
                                {/* {productdata?.data.map(order => (
                                    <Col xl={4} md={4} xs={6}>
                                        <ProductCard order={order} />
                                    </Col>
                                ))} */}
                                {productdata?.data.length > 0 ? (
                                    productdata.data.map((order) => (
                                        <Col xl={4} md={4} xs={6} key={order.id}>
                                            <ProductCard order={order} custom={custom} />
                                        </Col>
                                    ))
                                ) : (
                                    <Col>
                                        <EmptyAnimation />
                                        <p className="no-data-found-text">No products available</p>
                                    </Col>
                                )}
                            </Row>
                            {/* 
                            <Stack spacing={2}>
                            
                                <Paginationss
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                />
                            </Stack> */}
                            <Pagination>
                                <Pagination.First
                                    onClick={() => handlePageChange(1)}
                                    disabled={activePage === 1}
                                />
                                <Pagination.Prev className="custom-pagination-prev"
                                    onClick={() => handlePageChange(Math.max(activePage - 1, 1))}
                                    disabled={activePage === 1}
                                />
                                {paginationItems}
                                <Pagination.Next className="custom-pagination-next"
                                    onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))}
                                    disabled={activePage === totalPages}
                                />
                                <Pagination.Last
                                    onClick={() => handlePageChange(totalPages)}
                                    disabled={activePage === totalPages}
                                />
                            </Pagination>
                        </Col>
                    </Row>

                    <FilterOffcanvas
                        placement="start"
                        name={"start"}
                        show={show}
                        handleClose={handleClose}
                    />
                </div>
            </section>
        </>
    );
};

export default CustomizableProduct;
