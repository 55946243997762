import React, { useEffect, useState, useContext } from 'react'
import './ShopByOccasion.css'
import { Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

import { Link } from 'react-router-dom';
import { Context } from "../../../utils/context";
const ShopByOccasion = ({occasionprod}) => {
   
    const { IMG_URL } = useContext(Context);
   
    const weExprss = [
        {
            text: "NEW JOINING",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",

        },
        {

            text: "BIRTHDAY",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",

        },
        {
            text: "WORK ANNIVERSARY",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",
        },
        {
            text: "GRATITUDE",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",
        },
        {
            text: "NEW JOINING",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",

        },
        {

            text: "BIRTHDAY",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",

        },



    ];
    return (
        <>
            <section className='shop-by-occasion'>
                <Container>
                    <Row>
                        <div className='heading-holder'>
                            <div className='d-flex'>
                                <h2 className='first-heading'>Shop by </h2>
                                <h3 className='second-heading'>Occasion / Events</h3>
                            </div>
                            <span className='text-para'>Find The Perfect Match For Every Moment!</span>
                        </div>
                        <Swiper
                            loop="true"
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                486: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                1200: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                1400: {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                                },

                            }}
                            pagination={{
                                dynamicBullets: true,
                                clickable: true
                            }}
                            autoplay={{
                                delay: 2000, // 3 seconds delay between slides
                                disableOnInteraction: false, // allows autoplay to continue after interactions
                              }}
                              modules={[Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            {occasionprod?.length > 0 ? (
                                occasionprod.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className='main-div mb-5'>
                                            <div className='image-holder'>
                                                <Link to={`/product/${item.id}`}>
                                                    <img src={IMG_URL + item?.image} className='birthday-image' />

                                                </Link>
                                            </div>
                                            <h3>{item?.name}</h3>
                                        </div>
                                    </SwiperSlide>
                                ))
                            ) : (
                                <div>No data available</div>
                            )}
                        </Swiper>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default ShopByOccasion
