import React, { useEffect, useRef, useState, useContext } from 'react'
import { Container, Row } from 'react-bootstrap'
import './WeExpress.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";
import { Context } from "../../../utils/context";
import { Gratitude } from "../../../utils/apis/master/Master";

const WeExpress = ({ data }) => {
    const weExprss = [
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/hp_logo.png",

        },
        {


            image: process.env.PUBLIC_URL + "/assest/image/home/samsung.png",

        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/lg-company.png",
        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/asian-paints.png",
        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/hp_logo.png",

        },
        {


            image: process.env.PUBLIC_URL + "/assest/image/home/samsung.png",

        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/lg-company.png",
        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/asian-paints.png",
        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/hp_logo.png",

        },
        {


            image: process.env.PUBLIC_URL + "/assest/image/home/samsung.png",

        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/lg-company.png",
        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/asian-paints.png",
        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/hp_logo.png",

        },
        {


            image: process.env.PUBLIC_URL + "/assest/image/home/samsung.png",

        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/lg-company.png",
        },
        {

            image: process.env.PUBLIC_URL + "/assest/image/home/asian-paints.png",
        },
    ];
    useEffect(() => {
        AOS.init({ once: true });
    }, []);
    const { IMG_URL } = useContext(Context);

    return (
        <>
            <section className='we-express'>
                <Container data-aos="fade-up"
                    data-aos-duration="3000">
                    <Row>
                        <div className='heading-holder text-center my-lg-5 my-md-4 my-sm-3 my-3'>
                            <h2>Grateful to our Customer</h2>
                            <p>Your Trust Drives Our Success.</p>
                        </div>
                        <Swiper
                            loop={true}
                            breakpoints={{
                                0: { slidesPerView: 4, spaceBetween: 5 },
                                486: { slidesPerView: 5, spaceBetween: 20 },
                                768: { slidesPerView: 6, spaceBetween: 20 },
                                992: { slidesPerView: 8, spaceBetween: 20 },
                                1200: { slidesPerView: 10, spaceBetween: 30 },
                                1400: { slidesPerView: 8, spaceBetween: 60 },
                            }}
                            pagination={{
                                dynamicBullets: true,
                                clickable: true
                            }}
                            autoplay={{
                                delay: 0, // No delay
                                disableOnInteraction: false,
                            }}
                            speed={1000} // Smooth transition speed in milliseconds
                            // modules={[Autoplay]}
                            className="mySwiper"
                        >
                            {data?.length > 0 ? (
                                data.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className='main-div'>
                                            <div className='image-holder'>
                                                <img src={IMG_URL + item?.image} className='company-logo' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            ) : (
                                <div className="no-data">
                                    <p style={{ textAlign: "center", fontWeight: "bold", margin: 0 }}>
                                        No data available
                                    </p>
                                </div>
                            )}
                        </Swiper>

                    </Row>
                </Container>
            </section>
        </>
    )
}

export default WeExpress
