import React, { useEffect, useRef, useState, useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './SavingOffer.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Context } from "../../../utils/context";
import { HomeOffer } from "../../../utils/apis/Home/Homeapi";
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";
const SavingOffer = ({data}) => {
    const weExprss = [
        {
            headingtext: "Surprise Your Team with",
            headingtexttwo: "with Our Exciting Offers",
            text: "Best Corporate Gifts",
            image: process.env.PUBLIC_URL + "/assest/image/home/ban-shop.png",

        },
        {
            headingtext: "Surprise Your Team with",
            headingtexttwo: "with Our Exciting Offers",
            text: "Best Corporate Gifts",
            image: process.env.PUBLIC_URL + "/assest/image/home/ban-shop.png",

        },

    ];

    
    const { id } = useParams();
    const { IMG_URL } = useContext(Context);
    

    
    return (
        <>
            <section className='saving-offer'>
                <Container>
                    <Row>
                        <div className='heading-holder'>
                            <div className='d-flex'>
                                <h2 className='first-heading'>Super  </h2>
                                <h3 className='second-heading'>Saving Offers</h3>
                            </div>
                        </div>
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={1}
                            pagination={{
                                dynamicBullets: true,
                                clickable: true
                            }}
                            autoplay={{
                                delay: 5000, // 3 seconds delay between slides
                                disableOnInteraction: false, // allows autoplay to continue after interactions
                              }}
                              modules={[Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            {data?.length ? (
                                data.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className='main-div mb-5'>
                                            <div className='image-holder'>
                                                <img src={IMG_URL + item.image} className='bann-image' />
                                            </div>
                                            <div className='text-holder'>
                                                {/* <h4>{item.name}</h4> */}
                                                {/* <h3>{item.text}</h3>
                                            <h4>{item.headingtexttwo}</h4> */}
                                            </div>
                                            {/* <div className='overlay-color'></div> */}
                                        </div>
                                    </SwiperSlide>
                                ))
                            ) : (
                                <p>Data not available</p>
                            )}
                        </Swiper>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SavingOffer
