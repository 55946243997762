import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";

import SuccessfullyModal from "../../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import { Checkout } from "../../../utils/apis/orderProcess/orderProcess";
import {
  postAddress,
  getAddress,
  deleteAddress,
  getSingleAddress,
  postorder, postPayu, postPayment,
} from "../../../utils/apis/orderProcess/orderProcess";

import useRazorpay from "react-razorpay";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {
  Country,
  State,
  City,
  Pincode,
} from "../../../utils/apis/master/Master";

import {
  AddToCart,
  getCart,
  deleteCart,
} from "../../../utils/apis/addToCart/addToCart";
const Order_review = ({
  handleNext,
  ShippingId,
  address_id,
  giftdata,
  giftdataArray,
  paymentMethod,
  handlePrevious,
  setAddressSelect,
  addressSelect, paymentData, setPaymentData,dataId,setdataID
}) => {
  const [orderplace, setOrderplace] = useState(false);
  // const cart = [
  //     { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png", product_title: "Nike Dri-FIT Legend Tee - Men's", selling_price: 3000, mrp: 4500 },
  // ]
  const navigate = useNavigate();
  const placeOrder = () => {
    setOrderplace(true);
    setTimeout(() => {
      setOrderplace(false);
      window.location = "/home";
    }, 3000);
  };
  const [totalGiftInduasalPrice, setTotalGiftInduasalPrice] = useState(0);
  const [totalCustomePrice, setTotalcustomePrice] = useState(0);


  console.log(totalCustomePrice, 'totalCustomePrice totalCustomePrice totalCustomePrice');

  const {
    IMG_URL,
    signin,
    setCartCount,
    setTrigger,
    updateCartCount,
  } = useContext(Context);
  const hideModalWithDelay = () => {
    setTimeout(() => {
      setOrderplace(false);
      navigate("/home");
    }, 3000);
  };

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const handleRazorpay = async (storedValue) => {
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(storedValue) * 100,
      currency: "INR",
      name: "Hiclousia",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  // Add isProcessed as a dependency to avoid re-running

  const finalFunction = async (razor = null) => {
    console.log("jfffffffffffffffffffffff");

    const data = {
      shipping_address_id: ShippingId,
      billing_address_id: address_id,
      amount: cart?.message?.total,
      to: giftdata?.to,
      from: giftdata?.from,
      message: giftdata?.message,
      gift_status: giftdata?.gift_status,
      gift_details: giftdataArray,
      gift_price_total: totalGiftInduasalPrice,
      payment_mode: razor ? "online" : "cod",
    };

    if (razor) {
      data.payment_id = razor;
    }
    const res = await postorder(data);
    if (res?.success) {
      setOrderplace(true);
      setTimeout(() => {
        setOrderplace(false);
        window.location = "/home";
      }, 3000);
    }
  };

  // useEffect(() => {
  //   setOrderplace(true);
  //   setTimeout(() => {
  //     setOrderplace(false);
  //     setUserData(null)
  //     window.location = "/home";
  //   }, 3000);
  // finalFunction(userData);

  // }, [userData]);

  // console.log(userData,'userData userData userData userData');

  const [cart, setCart] = useState([]);
  const [billingdata, setBillingData] = useState([]);
  const [shippingdata, setShippingData] = useState([]);
  const cartGet = async () => {
    const res = await getCart();
    if (res?.success) {
      setCart(res);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };
  const singleBillingAddress = async () => {
    const res = await getSingleAddress(address_id);
    if (res?.success) {
      setBillingData(res?.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };
  const singleshippingAddress = async () => {
    const res = await getSingleAddress(ShippingId);
    if (res?.success) {
      setShippingData(res?.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  useEffect(() => {
    cartGet();
    singleBillingAddress();
    singleshippingAddress();
  }, [giftdata, giftdataArray]);

  useEffect(() => {
    setdataID(paymentData?.order?.id);
  }, [paymentData]);
console.log(dataId,"dataId dataId");

  useEffect(() => {
    let total;
    if (giftdataArray && giftdataArray?.length > 0) {
      total = giftdataArray
        ?.filter((cartDetail) => cartDetail?.gift_status === 1)
        .reduce((total, cartDetail) => {
          const giftPrice = parseFloat(
            cartDetail?.gift_price * cartDetail?.quantity
          );
          return total + giftPrice;
        }, 0);
    } else if (giftdata) {
      // total = cart?.data?.reduce((total, cartDetail) => {
      //     const giftPrice = parseFloat(Number(cartDetail?.product?.product_detail?.gift_price) * cartDetail?.quantity);
      //     return total + giftPrice;
      // }, 0);
      total = cart?.message?.giftprice;
    }
    const custometotal = cart?.data?.reduce((total, cartDetail) => {
      const giftPrice = parseFloat(
        cartDetail?.customize?.custom_price * cartDetail?.quantity
      );
      console.log(giftPrice, 'giftPrice ppppppppppppppppppppppppppppp');

      return total + giftPrice;
    }, 0);

    setTotalcustomePrice(custometotal);
    setTotalGiftInduasalPrice(total);
  }, [giftdataArray, giftdata, cart]);

  // const [paymentData, setPaymentData] = useState(null);
  const handlePayment = async (amount) => {


    try {
      const data = {
        firstname: 'kalyani',
        productinfo: 'address_id',
        amount: 1,
        email: 'kalyani.raghatate@profcyma.com',
        phone: '7798231121',

      };
      //   const response = await fetch('http://localhost:5000/pay', {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify(formData),
      //   });
      const res = await postPayu(data);

      console.log(res, "res res res");

      //   const data1 = await res.json();
      setPaymentData(res);
    } catch (error) {
      console.error('Payment initiation failed', error);
    }
  };


  console.log(paymentData, "paymentData paymentData paymentData kkkkkkkkkkkkkkk");

  // const handlePayment = async (amount) => {
  //   // Assume getPaymentData fetches payment information for PayU
  //   // const paymentData = await getPaymentData(amount);
  //   const data = {
  //     firstname: 'kalyani',
  //     productinfo: 'address_id',
  //     amount: 2,
  //     email: 'kalyani.raghatate@profcyma.com',
  //     phone: '7798231121',
  //     // firstname: `${address?.f_name} ${address?.l_name}`, // Use template literals for clarity
  //     // productinfo: 'xyz', // Update this with actual product info if needed
  //     // amount: 1, // Replace with the actual amount you want to charge
  //     // email: 'kalyani.raghatate@profcyma.com', // Use the actual email
  //     // phone: '7798231121', // Use the actual phone number
  // };

  // // Call the postPayu function to initiate payment
  // const paymentData = await postPayu(data);
  //   // Check if paymentData exists
  //   if (paymentData) {
  //     // Create a form to submit the payment data to PayU
  //     const form = document.createElement("form");
  //     form.action = paymentData.actionUrl; // Make sure this URL is correct
  //     form.method = "POST";

  //     // Add hidden fields to the form for each piece of payment data
  //     Object.keys(paymentData).forEach((key) => {
  //       const hiddenField = document.createElement("input");
  //       hiddenField.type = "hidden";
  //       hiddenField.name = key;
  //       hiddenField.value = paymentData[key];
  //       form.appendChild(hiddenField);
  //     });

  //     document.body.appendChild(form);

  //     // Submit the form to PayU
  //     form.submit();

  //     // Optionally, get the payment ID if available
  //     const paymentId = paymentData.txnid; // Assuming txnid is the payment ID, adjust if needed

  //     // Call finalFunction with the payment ID
  //     if (paymentId) {
  //       await finalFunction(paymentId);
  //     }
  //     //  else {
  //     //   await finalFunction(); // Call without payment ID if none is available
  //     // }
  //   } else {
  //     console.error("Payment data is missing or invalid.");
  //     alert("There was an error initiating the payment. Please try again.");
  //   }
  // };

  // if (paymentData) {
  //   // Auto-submit the form to PayU
  //   return (
  //     <form action={paymentData.actionUrl} method="POST">
  //       {Object.keys(paymentData.paymentData).map((key) => (
  //         <input type="hidden" name={key} value={paymentData.paymentData[key]} key={key} />
  //       ))}
  //       <button type="submit">Proceed to PayU</button>
  //     </form>
  //   );
  // }


  const handleSubmitPayment = (event) => {
    event.preventDefault(); // Prevent default form submission

    // Create the form and submit it
    const form = event.target; // Use the current form
    const formData = new FormData(form); // Collect form data

    // Send the form data to the PayU action URL
    fetch(form.action, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Payment result", data);
        // Assuming the payment ID is in the response, adjust according to your API
        // const paymentId = data.txnid || null; // Get the payment ID from the response

        // if (paymentId) {
        //   // Call finalFunction with the payment ID
        // finalFunction();
        // } else {
        //   console.error("Payment ID is missing.");
        // }
      })
      .catch((error) => {
        console.error("Error processing payment", error);
      });
  };
  return (

    <>

      <section className="stepp-inner-main">
        <div className="orde-status-dv">
          <div className="orderReviewmain">
            <div className=" colwidth">
              <p>Order Number</p>
              <p>0005-12122023</p>
            </div>
            <div className=" colwidth">
              <p>Status</p>
              <p>Ordered</p>
            </div>
            <div className=" colwidth">
              <p>Date</p>
              <p>
                {new Date().toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </p>
            </div>
            <div className=" colwidth">
              <p>Total</p>
              <p>
                ₹ {" "}
                {(
                  (isNaN(parseFloat(cart?.message?.total_selling))
                    ? 0
                    : parseFloat(cart?.message?.total_selling)) +
                  (isNaN(totalGiftInduasalPrice)
                    ? 0
                    : totalGiftInduasalPrice) +
                  (isNaN(totalCustomePrice) ? 0 : totalCustomePrice) +
                  (Number(totalGiftInduasalPrice || 0) +
                    Number(totalCustomePrice || 0) +
                    Number(cart?.message?.total_selling || 0)) *
                  Number(cart?.message?.taxpercent || 0)
                ).toFixed(2)}
              </p>
            </div>
            <div className=" colwidth">
              <p>Payment method</p>
              <p>{paymentMethod}</p>
            </div>
          </div>
        </div>

        <div className="orderr-dettt">
          <h6 className="jhber">Order Details</h6>

          <div className="row">
            {cart?.data?.map((post, index) => (
              <>
                <div
                  className="col-lg-6 col-md-6 col-sm-8 col-10 detailssss-flex mt-3"
                  key={post.id}
                >
                  <div className="img-div">
                    <img
                      className="userimgg"
                      src={
                        IMG_URL +
                        post?.product_variant?.product_images[0]?.image
                      }
                      alt="Product"
                    />
                  </div>
                  <div className="contdivvv">
                    <p className="name">{post?.product?.name}</p>
                    {/* <p className='sizenumm'>38</p> */}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-4 col-2 my-auto">
                  <p className="mb-0 Prodprice">
                    ₹ {post?.product_variant?.s_price * post?.quantity}
                  </p>
                </div>
              </>
            ))}
          </div>

          <div className="row orde-status-dv">
            <div className="col-lg-8"></div>
            <div className="col-lg-4 text-end">
              <Row>
                <Col lg={6} md={10} xs={8}>
                  <p className="mb-0" style={{ fontWeight: "600" }}>
                    Subtotal
                  </p>
                </Col>

                <Col lg={6} md={2} xs={4}>
                  <p className="mb-0">
                    <strong>₹ {cart?.message?.total_selling}</strong>
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          <div className="totalprieee">
            <div className="row mb-4">
              <div className="col-lg-8 col-6">
                <h6>Gift Wrap Price</h6>
              </div>
              <div className="col-lg-4">
                <div className="row flatrateMain">
                  <div className="col-lg-6 col-6">
                    <h6 className="flatRate">Total</h6>
                  </div>
                  <div className="col-lg-6 col-6">
                    <h6 className="itemPrice">
                      ₹ {totalGiftInduasalPrice || 0.0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            {/* {totalCustomePrice != NaN && ( */}
            <div className="row mb-4">
              <div className="col-lg-8 col-6">
                <h6>customise Price</h6>
              </div>
              <div className="col-lg-4">
                <div className="row flatrateMain">
                  <div className="col-lg-6 col-6">
                    <h6 className="flatRate">Total</h6>
                  </div>
                  <div className="col-lg-6 col-6">
                    <h6 className="itemPrice">₹ {totalCustomePrice || 0}</h6>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
            <div className="row mb-4">
              <div className="col-lg-8 col-6">
                <h6>Total Tax</h6>
              </div>
              <div className="col-lg-4">
                <div className="row flatrateMain">
                  <div className="col-lg-6 col-6">
                    <h6 className="flatRate">Total</h6>
                  </div>
                  <div className="col-lg-6 col-6">
                    <h6 className="itemPrice">
                      ₹ {" "}
                      {(
                        (Number(totalGiftInduasalPrice || 0) +
                          Number(totalCustomePrice || 0) +
                          Number(cart?.message?.total_selling || 0)) *
                        Number(cart?.message?.taxpercent || 0)
                      ).toFixed(2)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-8 col-6">
                <h6>Payment Method</h6>
              </div>
              <div className="col-lg-4 col-6">
                <div className="row flatrateMain">
                  <div className="col-lg-6 col-6">
                    <h6 className=" flatRate">{paymentMethod}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row totalbg mb-3">
              <div className="col-lg-8 col-6">
                <h6>
                  <b>Total</b>
                </h6>
              </div>

              <div className="col-lg-4 col-6">
                <h6 className="totalorderPrice">
                  ₹
                  {(
                    (isNaN(parseFloat(cart?.message?.total_selling))
                      ? 0
                      : parseFloat(cart?.message?.total_selling)) +
                    (isNaN(totalGiftInduasalPrice)
                      ? 0
                      : totalGiftInduasalPrice) +
                    (isNaN(totalCustomePrice) ? 0 : totalCustomePrice) +
                    (Number(totalGiftInduasalPrice || 0) +
                      Number(totalCustomePrice || 0) +
                      Number(cart?.message?.total_selling || 0)) *
                    Number(cart?.message?.taxpercent || 0)
                  ).toFixed(2)}
                </h6>
                {/* <h6 className='totalorderPrice'>₹.{cart?.message?.total+totalGiftInduasalPrice+totalCustomePrice}</h6>  */}
                {/* <h6 className='totalorderPrice'>₹.{parseFloat(cart?.message?.total ?? 0) +totalGiftInduasalPrice ?? 0+totalCustomePrice ?? 0}</h6> */}
              </div>
            </div>

            {/* <p className='earnPoints'>You will Earn: 0 Points</p> */}

            <div className="row mb-4">
              <Col lg={6} md={6}>
                <h6>
                  <b>Billing Address</b>
                </h6>
                <hr></hr>

                <p className="finaladddd">
                  {billingdata?.company}, {billingdata?.address},{" "}
                  {billingdata?.city_id?.label}, {billingdata?.state_id?.label},{" "}
                  {billingdata?.country_id?.label} -{" "}
                  {billingdata?.pincode_id?.label}
                </p>
              </Col>
              <Col lg={6} md={6}>
                <h6>
                  <b>Shipping Address</b>
                </h6>
                <hr></hr>
                <p className="finaladddd">
                  {shippingdata?.company}, {shippingdata?.address},{" "}
                  {shippingdata?.city_id?.label},{" "}
                  {shippingdata?.state_id?.label},{" "}
                  {shippingdata?.country_id?.label} -{" "}
                  {shippingdata?.pincode_id?.label}
                </p>
              </Col>
            </div>
          </div>
        </div>

        <div className="save-btn-div mt-5 ">
       <div className="d-flex justify-content-center">

          <button type="button" className="back-btn" onClick={handlePrevious}>
            Back
          </button>
          {paymentMethod == "cod" ? (
            <button
              type="button"
              className="placeOrderbtn"
              onClick={() => finalFunction()}
            >
              Place Order
            </button>
          ) : (

            //   <form action={paymentData.actionUrl} method="POST">
            //   {Object.keys(paymentData.paymentData).map((key) => (
            //     <input type="hidden" name={key} value={paymentData.paymentData[key]} key={key} />
            //   ))}
            //   <button type="submit">Place Order</button>
            // </form>
            <form action={paymentData.actionUrl} method="POST" onSubmit={handleSubmitPayment}>
              {Object.keys(paymentData.paymentData).map((key) => (
                <input type="hidden" name={key} value={paymentData.paymentData[key]} key={key} />
              ))}
              <button className="placeOrderbtn" type="submit">Place Order</button>
            </form>

          )}
       </div>
          <p className="editforgettxt">
            <Link to={"/mycart"}>
              <span>Forgot an Item?</span> <span>Edit Your Cart</span>
            </Link>
          </p>
        </div>
      </section>

      <SuccessfullyModal
        show={orderplace}
        onHide={() => setOrderplace(false)}
        text={"Order Placed Successfully"}
      />
    </>
  );
};

export default Order_review;


// import React, { useState, useEffect } from 'react';
// import { Col, Row } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import { Link } from 'react-router-dom';
// import RewardDetails from '../../RewardDetails/RewardDetails';
// import {
//     postAddress,
//     getAddress,
//     deleteAddress,
//     getSingleAddress, getSingleShippingAddress,
//     postorder, postPayu,
//     paymentMethod,
// } from "../../../utils/apis/orderProcess/orderProcess";
// import {
//     AddToCart,
//     getCart,
//     deleteCart,
// } from "../../../utils/apis/addToCart/addToCart";
// const Payment_info = ({
//     ShippingId,
//     address_id,
//     giftdata,
//     giftdataArray,
//     handleNext,
//     handlePrevious,
//     paymentMethod,
//     setPaymentMethod,
//     setPaymentData,
//     paymentData }) => {
//     const [selectedPayment, setSelectedPayment] = useState('');
//     const [totalGiftInduasalPrice, setTotalGiftInduasalPrice] = useState(0);
//     const [totalCustomePrice, setTotalcustomePrice] = useState(0);
//     const [isChecked, setIsChecked] = useState(false);
//     const [showRewardDetails, setShowRewardDetails] = useState(false);

//     const handlePaymentChange = (event) => {
//         setSelectedPayment(event.target.value);
//     };

//     const handleCheckboxChange = () => {
//         setIsChecked(!isChecked);
//     };

//     // const handleLearnMoreClick = () => {
//     //     setShowRewardDetails(true);
//     //     setCheckout(false);
//     // };


//     const [address, setadress] = useState({});
//     const GetEditShippingData = async () => {
//         const response = await getSingleShippingAddress(ShippingId);
//         setadress(response?.data);
//         // reset(response?.data);
//     };
//     console.log(address, "address aaaaaaaaaaaaaaaaaaa");

//     const [data, setdata] = useState({});
//     const [cart, setCart] = useState([]);
//     const cartGet = async () => {
//         const res = await getCart();
//         if (res?.success) {
//             setCart(res);
//         } else {
//             console.error("Error fetching data:", res?.error);
//         }
//     };
//     useEffect(() => {
//         cartGet();
//         GetEditShippingData();

//     }, [paymentMethod]);

//     const handlePayment = async (value) => {

//         // Set the selected payment method
//         setPaymentMethod(value);

//         try {
//             // Prepare payment data
//             const data = {
//                 firstname: `${address?.f_name} ${address?.l_name}`,
//                 shipping_address_id: ShippingId,
//                 billing_address_id: address_id,
//                 amount: cart?.message?.total,
//                 to: giftdata?.to,
//                 from: giftdata?.from,
//                 message: giftdata?.message,
//                 gift_status: giftdata?.gift_status,
//                 gift_details: giftdataArray,
//                 gift_price_total: totalGiftInduasalPrice,
//                 payment_mode: value,
//             };

//             // Call the postPayu function to initiate payment
//             const res = await postorder(data);
//             if (res?.success) {
//                 console.log(res, "Payment Response");
//              const data=res?.data;
//              console.log(data,"data data");
//              setdata(data)
//             await setPaymentData(data); // Make sure you're accessing the correct field in the response
//             } else {
//                 console.error('Invalid payment response:', res);
//             }
//         } catch (error) {
//             console.error('Payment initiation failed', error);
//         }
//     };

// console.log(data,'data data data data');

//     // if (paymentMethod == 'online') {
//     //     handlePayment(cart?.message?.total); // Pass the amount you want to use
//     // }
//     const handleMethod = async (value) => {
//         await setPaymentMethod(value);
//     }


//     console.log(paymentData, 'paymentData paymentData zzzzzzzzzzzzzzzzz');

//     const [billingdata, setBillingData] = useState([]);
//     const [shippingdata, setShippingData] = useState([]);
//     const singleBillingAddress = async () => {
//         const res = await getSingleAddress(address_id);
//         if (res?.success) {
//             setBillingData(res?.data);
//         } else {
//             console.error("Error fetching data:", res?.error);
//         }
//     };
//     const singleshippingAddress = async () => {
//         const res = await getSingleAddress(ShippingId);
//         if (res?.success) {
//             setShippingData(res?.data);
//         } else {
//             console.error("Error fetching data:", res?.error);
//         }
//     };

//     useEffect(() => {
//         cartGet();
//         singleBillingAddress();
//         singleshippingAddress();
//     }, [giftdata, giftdataArray]);

//     useEffect(() => {
//         let total;
//         if (giftdataArray && giftdataArray?.length > 0) {
//             total = giftdataArray
//                 ?.filter((cartDetail) => cartDetail?.gift_status === 1)
//                 .reduce((total, cartDetail) => {
//                     const giftPrice = parseFloat(
//                         cartDetail?.gift_price * cartDetail?.quantity
//                     );
//                     return total + giftPrice;
//                 }, 0);
//         } else if (giftdata) {
//             // total = cart?.data?.reduce((total, cartDetail) => {
//             //     const giftPrice = parseFloat(Number(cartDetail?.product?.product_detail?.gift_price) * cartDetail?.quantity);
//             //     return total + giftPrice;
//             // }, 0);
//             total = cart?.message?.giftprice;
//         }
//         const custometotal = cart?.data?.reduce((total, cartDetail) => {
//             const giftPrice = parseFloat(
//                 cartDetail?.customize?.custom_price * cartDetail?.quantity
//             );
//             console.log(giftPrice, 'giftPrice ppppppppppppppppppppppppppppp');

//             return total + giftPrice;
//         }, 0);

//         setTotalcustomePrice(custometotal);
//         setTotalGiftInduasalPrice(total);
//     }, [giftdataArray, giftdata, cart]);
//     return (
//         <>
//             <section className='stepp-inner-main'>
//                 <div className='row'>
//                     {
//                         !showRewardDetails && (
//                             <div className='col-lg-10 mx-auto'>
//                                 <Row className='step4check'>
//                                     <Col lg={6} md={6}>
//                                         <div className='bankTransfer'>
//                                             <Form.Check // prettier-ignore
//                                                 type="radio"
//                                                 name="method"
//                                                 label="Online"
//                                                 value="online"
//                                                 defaultChecked={paymentMethod === 'online'}
//                                                 onClick={() => handlePayment('online')}
//                                             // onClick={() => handleMethod('online')}
//                                             />

//                                         </div>
//                                     </Col>
//                                     <Col lg={6} md={6}>
//                                         <div className='razorpaycheck'>
//                                             <Form.Check // prettier-ignore
//                                                 type="radio"
//                                                 name="method"
//                                                 label="Cash on delivery"
//                                                 value="cod"
//                                                 defaultChecked={paymentMethod === 'cod'}
//                                                 onClick={() => handleMethod('cod')}
//                                             />

//                                         </div>
//                                     </Col>
//                                 </Row>
//                                 {/* {selectedPayment === 'bankTransfer' && (
//                                     <div className='row'>
//                                         <Col lg={6}>
//                                             <div className='bankInput'>
//                                                 <Form.Check
//                                                     type='checkbox'
//                                                     label={
//                                                         <>
//                                                             <Form.Group className=" mb-2" controlId="exampleForm.ControlInput1">
//                                                                 <Form.Control type="email" placeholder="Transfer amount" />
//                                                             </Form.Group>
//                                                         </>
//                                                     }
//                                                     onChange={handleCheckboxChange}
//                                                 />
//                                                 {isChecked && (
//                                                     <div>
//                                                         <p className='step5Paymentlink'>
//                                                             <span>Each of 1 Point gets ₹1.00 discount. You need to earn more 1 point(s) to use this rule.</span>
//                                                             <span className='click-here' onClick={handleLearnMoreClick} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
//                                                                 Please click here to learn about it.
//                                                             </span>
//                                                         </p>
//                                                     </div>
//                                                 )}
//                                             </div>
//                                         </Col>
//                                     </div>
//                                 )} */}
//                                 {selectedPayment === 'razorpay' && (
//                                     <Row>
//                                         <Col xl={6} className='ms-auto'>
//                                             <div className='razorpayDetails'>
//                                                 <p>You will be redirected to Razorpay Checkout page when you place an order.</p>
//                                             </div>

//                                         </Col>
//                                     </Row>
//                                 )}
//                             </div>
//                         )
//                     }
//                 </div>
//                 {!showRewardDetails && (
//                     <div className="save-btn-div mt-5">
//                         <button type='button' className="back-btn" onClick={handlePrevious}>Back</button>
//                         <button type='button' className="continue-btn" onClick={handleNext}>Continue</button>
//                     </div>
//                 )}
//             </section>

//             {showRewardDetails && <RewardDetails />}
//         </>
//     );
// }

// export default Payment_info;
