import React, { useEffect, useRef, useState, useContext } from 'react'
import './OrderSummary.css'
import { Col, Row } from 'react-bootstrap'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { AddToCart, getCart, deleteCart } from "../../../utils/apis/addToCart/addToCart";
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";

import { Context } from "../../../utils/context";
const OrderSummary = ({cart, data, totalTax, totalPrice ,total}) => {
  
    const { IMG_URL, signin } = useContext(Context);

    console.log("OrderSummary",cart)

    return (
        <section className='order-summary'>
            <div className='title-order-sumarryy d-flex'>
                <FontAwesomeIcon icon={faClipboard} className='pad-icon me-2' />
                <h4>Order Summary</h4>
                <div className='blk-borderr'></div>
            </div>
            {signin ? (
                <div className='main-blue-bggg'>
                    <Row>
                        <Col md={6}>
                            <p className='txttt-order-summary'>Sub Total ({cart?.data?.length} items)</p>
                        </Col>
                        <Col md={6}>
                            <p className='price-order-summary'>₹ {data?.total_selling}</p>
                        </Col>
                    </Row>
                    <div className='brown-border'></div>
                    {/* <Row>
                        <Col md={6}>
                            <p className='txttt-order-summary'>Discount</p>
                        </Col>
                        <Col md={6}>
                            <p className='price-order-summary'>₹ 0</p>
                        </Col>
                    </Row> */}
                    <div className='brown-border'></div>
                    <Row>
                        <Col md={6}>
                            <p className='txttt-order-summary'>Tax</p>
                        </Col>
                        <Col md={6}>
                            <p className='price-order-summary'>₹ {data?.tax}</p>
                        </Col>
                    </Row>
                    <div className='brown-border'></div>
                    <Row>
                        <Col md={6}>
                            <p className='txttt-order-summary'>Shipping</p>
                        </Col>
                        <Col md={6}>
                            <p className='at-checkout-label'>Calculated at Checkout</p>
                        </Col>
                    </Row>
                    <div className='brown-border'></div>
                    <Row>
                        <Col md={6}>
                            <p className='price-totaltxt'>Total</p>
                        </Col>
                        <Col md={6}>
                            <p className='price-totaltxt'><b>₹ {data?.total}</b></p>
                        </Col>
                    </Row>
                </div>
            ) : (

                <div className='main-blue-bggg'>
                    <Row>
                        <Col md={6}>
                            <p className='txttt-order-summary'>Subtotal ({cart?.data?.length} items)</p>
                        </Col>
                        <Col md={6}>
                            <p className='price-order-summary'>₹ {totalPrice}</p>
                        </Col>
                    </Row>
                    <div className='brown-border'></div>
                    {/* <Row>
                            <Col md={6}>
                                <p className='txttt-order-summary'>Discount</p>
                        </Col>
                        <Col md={6}>
                            <p className='price-order-summary'>₹ 0</p>
                        </Col>
                    </Row> */}
                    <div className='brown-border'></div>
                    <Row>
                        <Col md={6}>
                            <p className='txttt-order-summary'>Tax</p>
                        </Col>
                        <Col md={6}>
                            <p className='price-order-summary'>₹ {totalTax}</p>
                        </Col>
                    </Row>
                    <div className='brown-border'></div>
                    <Row>
                        <Col md={6}>
                            <p className='txttt-order-summary'>Shipping</p>
                        </Col>
                        <Col md={6}>
                            <p className='price-order-summary'>₹ 0000</p>
                        </Col>
                    </Row>
                    <div className='brown-border'></div>
                    <Row>
                        <Col md={6}>
                            <p className='price-totaltxt'>Total</p>
                        </Col>
                        <Col md={6}>
                            <p className='price-totaltxt'><b>₹ {total}</b></p>
                        </Col>
                    </Row>
                </div>
            )}

            {signin ? (
                <div className='mt-4'>
                    <Link to={"/checkout"}>
                        <button type='button' className='proceed-btnnn'>PROCEED TO CHECKOUT</button>

                    </Link>

                    <Link to={"/promotional-product"}>
                        <p className='continue-shopping mt-3'>Continue Shopping</p>
                    </Link>
                </div>
            ) : (
                <div className='mt-4'>
                    <Link to={"/login"}>
                        <button type='button' className='proceed-btnnn'>PROCEED TO CHECKOUT</button>

                    </Link>

                    <Link to={"/promotional-product"}>
                        <p className='continue-shopping mt-3'>Continue Shopping</p>
                    </Link>
                </div>

            )}

        </section>
    )
}

export default OrderSummary