import React, { useEffect, useRef, useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "react-medium-image-zoom/dist/styles.css";
import "./ProductDetails.css";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BestSellers from "../BestSellers/BestSellers";
import BestMatched from "../BestMatched/BestMatched";
import RecentlyViewed from "../RecentlyViewed/RecentlyViewed";
import { Link } from "react-router-dom";
import ReviewVideomodal from "../Common-Component/Commonmodal/ReviewVideomodal/ReviewVideomodal";
import Heart_Component from "../Common-Component/Heart_Component/Heart_Component";
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Context } from "../../utils/context";
import {
  getProductVariant,
  getVariant,
} from "../../utils/apis/Product/Product";
import { ClothSize } from "../../utils/apis/master/Master";
import { AddToCart } from "../../utils/apis/addToCart/addToCart";
import {
  getCart,
  deleteCart,
  logCartData,
} from "../../utils/apis/addToCart/addToCart";
import Cookies from "js-cookie";
import useCookie from "../MyCart/ProductOrder/cookiesStore";
import {
  allProductReviewGet,
  productReviewGet,
  productReviewPost,
} from "../../utils/apis/Product/Product";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies_popup_modal from "../Common-Component/Commonmodal/Cookies_popup_modal/Cookies_popup_modal";
import Heart_Component_Button from "../Common-Component/Heart_Component/Heart_Component_Button";
const ProductDetails = () => {

  const location = useLocation();

  // Use URLSearchParams to extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const subcategorytype = queryParams.get('subcategorytype');

  const [selectedImage, setSelectedImage] = useState(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [reviewshow, setReviewshow] = useState(false);
  const [Imageshow, setImageshow] = useState(false);
  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const sizes = [
    { info: "N/A", size: "Small", color: "red" },
    { info: "N/A", size: "Medium" },
    { info: "N/A", size: "Large" },
    { info: "N/A", size: "XL" },
    { info: "N/A", size: "2XL" },
    { info: "N/A", size: "3XL" },
    { info: "N/A", size: "4XL" },
    // add more objects if needed
  ];

  const data = [
    { size: "12-24", quantity: "₹ 0000" },
    { size: "12-24", quantity: "₹ 0000" },
    { size: "12-24", quantity: "₹ 0000" },
    { size: "12-24", quantity: "₹ 0000" },
    { size: "12-24", quantity: "₹ 0000" },
    { size: "12-24", quantity: "₹ 0000" },
    { size: "12-24", quantity: "Get a Quote" },
  ];

  class Zoomable {
    constructor(element, config) {
      this.element = element;
      this.config = this._mergeConfig(config);

      const { initialZoom, minZoom, maxZoom } = this.config;

      this.zoomed = false;
      this.initialZoom = Math.max(Math.min(initialZoom, maxZoom), minZoom);
      this.zoom = this.initialZoom;

      this.img = element.querySelector(".zoomable__img");
      this.img.draggable = false;
      this.element.style.setProperty("--zoom", this.initialZoom);

      this._addEventListeners();
    }

    static get Default() {
      return Default;
    }

    _addEventListeners() {
      this.element.addEventListener("mouseenter", () =>
        this._handleMouseover()
      );
      this.element.addEventListener("mousemove", (evt) =>
        this._handleMousemove(evt)
      );
      this.element.addEventListener("mouseleave", () => this._handleMouseout());
      this.element.addEventListener("wheel", (evt) => this._handleWheel(evt));
    }

    _handleMouseover() {
      if (this.zoomed) {
        return;
      }

      this.element.classList.add("zoomable--zoomed");
      this.zoomed = true;
    }

    _handleMousemove(evt) {
      if (!this.zoomed) {
        return;
      }

      const elPos = this.element.getBoundingClientRect();
      const percentageX = `${((evt.clientX - elPos.left) * 100) /
        elPos.width}%`;
      const percentageY = `${((evt.clientY - elPos.top) * 100) /
        elPos.height}%`;

      this.element.style.setProperty("--zoom-pos-x", percentageX);
      this.element.style.setProperty("--zoom-pos-y", percentageY);
    }

    _handleMouseout() {
      if (!this.zoomed) {
        return;
      }

      this.element.style.setProperty("--zoom", this.initialZoom);
      this.element.classList.remove("zoomable--zoomed");
      this.zoomed = false;
    }

    _handleWheel(evt) {
      if (!this.zoomed) {
        return;
      }

      evt.preventDefault();

      const newZoom = this.zoom + evt.deltaY * (this.config.zoomSpeed * -1);
      const { minZoom, maxZoom } = this.config;

      this.zoom = Math.max(Math.min(newZoom, maxZoom), minZoom);
      this.element.style.setProperty("--zoom", this.zoom);
    }

    _mergeConfig(config) {
      return {
        ...this.constructor.Default,
        ...(typeof config === "object" ? config : {}),
      };
    }
  }

  const Default = {
    initialZoom: 3,
    minZoom: 1.25,
    maxZoom: 4,
    zoomSpeed: 0.01,
  };

  const zoomableRef = useRef(null);

  useEffect(() => {
    const element = zoomableRef.current;
    if (!element) return;

    const zoomable = new Zoomable(element);

    return () => {
      element.removeEventListener("mouseenter", zoomable._handleMouseover);
      element.removeEventListener("mousemove", zoomable._handleMousemove);
      element.removeEventListener("mouseleave", zoomable._handleMouseout);
      element.removeEventListener("wheel", zoomable._handleWheel);
    };
  }, []);

  const images = [
    { image: process.env.PUBLIC_URL + "/assest/image/Careers/medal.png" },
    { image: process.env.PUBLIC_URL + "/assest/image/Careers/prod2.png" },
    { image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png" },
    { image: process.env.PUBLIC_URL + "/assest/image/Careers/medal.png" },
    { image: process.env.PUBLIC_URL + "/assest/image/Careers/prod2.png" },
    { image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png" },
  ];


  const navigate = useNavigate();
  const [varient, setVarient] = useState();
  const [sizeInput, setSizeInput] = useState(false);
  const [productdata, setproductData] = useState();
  const [error, setError] = useState("");
  const { id } = useParams();
  const [cart, setCart] = useState();
  const [cartWithout, setCartWithout] = useState();
  const [totalQuantity, setTotalQuantity] = useState(0);
  const {
    IMG_URL,
    setAddCartModal,
    signin,
    isCookieAccepted,
    setIsCookieAccepted,
  } = useContext(Context);
  const [showCookiesModal, setShowCookiesModal] = useState(false);
  const getAllData = async () => {
    const res = await getProductVariant(id);
    if (res?.success) {
      setproductData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    getAllData();
  }, [id]);

  const getSinglevarient = async (id) => {
    setSelectedImage(null);

    const res = await getVariant(id);
    if (res?.success) {
      if (res?.data?.product_cloth_sizes?.length > 0) {
        setSizeInput(true);
      }
      setVarient(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    if (productdata && productdata.length > 0 && productdata[0]?.id) {
      getSinglevarient(productdata[0].id);
    }
  }, [productdata]);

  const [sizedata, setsizeData] = useState();
  const getAllsize = async () => {
    const res = await ClothSize();
    if (res?.success) {
      setsizeData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  const [formData, setFormData] = useState({
    product_id: varient?.product?.id,
    varient_id: varient?.id,
    size: [],
  });

  let Itemdata;
  useEffect(() => {
    const sizedatavalue = varient?.product_cloth_sizes?.map((item) => ({
      cloth_size_id: item.cloth_size_id,
      cloth_size_name: item.cloth_size?.name,
      quantity: 0,
    }));

    setFormData({
      size: sizedatavalue,
    });
  }, [varient]);

  useEffect(() => {
    getAllsize();
  }, []);

  useEffect(() => {
    if (cart) {
      Itemdata = cart.data.find(
        (cartDetail) =>
          cartDetail.product_id === varient?.product?.id &&
          cartDetail.variant_id === varient?.id
      );

      const sizedatavalue = varient?.product_cloth_sizes?.map((item) => {
        let cartItem;
        if (cart) {
          cartItem = cart.data.find(
            (cartDetail) =>
              cartDetail.cloth_size_id === item.cloth_size_id &&
              cartDetail.product_id === varient.product.id &&
              cartDetail.variant_id === varient.id
          );
        }

        return {
          cloth_size_id: item.cloth_size_id,
          cloth_size_name: item.cloth_size?.name,
          quantity: cartItem ? cartItem.quantity : 0, // Set quantity based on the cart item if it exists
        };
      });

      setFormData((prevData) => ({
        ...prevData,
        size: sizedatavalue,
      }));

      // Update totalQuantity based on Itemdata
      setTotalQuantity(Itemdata?.quantity || 0);
    } else if (cartWithout) {
      // Find the item in cartWithout that matches the product and variant IDs
      let Itemdata = cartWithout.find(
        (cartDetail) =>
          cartDetail.product_id === varient?.product?.id &&
          cartDetail.variant_id === varient?.id
      );

      // Map through the sizes and set the quantity based on the cart item if it exists
      const sizedatavalue = varient?.product_cloth_sizes?.map((item) => {
        let cartItem = cartWithout.find(
          (cartDetail) =>
            cartDetail.product_id === varient?.product?.id &&
            cartDetail.variant_id === varient?.id
        );

        // Find the specific size object within the sizes array
        const sizeDetail = cartItem?.sizes?.find(
          (cart) => cart.cloth_size_id === item.cloth_size_id
        );

        return {
          cloth_size_id: item.cloth_size_id,
          cloth_size_name: item.cloth_size?.name,
          quantity: sizeDetail ? sizeDetail.quantity : 0, // Set quantity based on the matched size object
        };
      });

      setFormData((prevData) => ({
        ...prevData,
        size: sizedatavalue,
      }));

      // Update totalQuantity based on Itemdata
      setTotalQuantity(Itemdata?.quantity || 0);
    }
    if (Cookies.get("customise_size")) {
      const cartWithout = JSON.parse(Cookies.get("customise_size"));

      // Check if the product_id and variant_id match the current variant
      if (
        cartWithout.product_id === varient?.product?.id &&
        cartWithout.variant_id === varient?.id
      ) {
        // Map through the variant's cloth sizes and update the quantity based on the cart item
        const sizedatavalue = varient?.product_cloth_sizes?.map((item) => {
          // Find the specific size object within the sizes array in the cart
          const sizeDetail = cartWithout.size?.find(
            (cart) => cart.cloth_size_id === item.cloth_size_id
          );

          return {
            cloth_size_id: item.cloth_size_id,
            cloth_size_name: item.cloth_size?.name,
            quantity: sizeDetail ? sizeDetail.quantity : 0, // Set quantity based on the matched size object or 0 if not found
          };
        });

        // Update the form data with the matched sizes and quantities
        setFormData((prevData) => ({
          ...prevData,
          size: sizedatavalue,
        }));

        // Calculate the total quantity by summing up the quantities of all matched sizes
        const totalQuantity = sizedatavalue.reduce(
          (acc, size) => acc + size.quantity,
          0
        );

        setTotalQuantity(totalQuantity);
      }
    }
  }, [varient, cart, cartWithout]);

  useEffect(() => {
    getAllsize();
  }, []);

  // const calculateTotalQuantity = () => {
  //     return formData?.size?.reduce((total, item) => total + item?.quantity, 0);
  // };
  // const Quantity = calculateTotalQuantity();
  // if(Quantity){
  //     setTotalQuantity(Quantity);
  // }
  useEffect(() => {
    const calculateTotalQuantity = () => {
      return formData?.size?.reduce(
        (total, item) => total + (parseInt(item.quantity) || 0),
        0
      );
    };
    const quantity = calculateTotalQuantity();
    if (quantity) {
      setTotalQuantity(quantity);
    }
  }, [formData]);

  const createAddTocart = async () => {
    if (!isCookieAccepted) {
      setShowCookiesModal(true);
      return;
    }

    if (varient?.product_cloth_sizes?.length > 0) {
      if (
        !formData?.size ||
        formData.size.length === 0 ||
        !formData.size.some((size) => size.quantity > 0)
      ) {
        setError("Please select at least one size.");
        return;
      }
    }
    if (totalQuantity === 0) {
      setError("Please select quantity.");
      return;
    }
    let data = {
      product_id: varient?.product?.id,
      variant_id: varient?.id,
      quantity: totalQuantity,
      size: formData?.size,
    };

    const res = await AddToCart(data);
    if (res?.success) {
      setAddCartModal();
    } else {
      console.error("Error adding to cart:", res?.error);
    }
    navigate("/mycart");
  };

  const customisecart = async () => {
    if (varient?.product_cloth_sizes?.length > 0) {
      if (
        !formData?.size ||
        formData.size.length === 0 ||
        !formData.size.some((size) => size.quantity > 0)
      ) {
        setError("Please select at least one size.");
        return;
      }
    }
    const newItem = {
      product_id: varient?.product?.id,
      variant_id: varient?.id,
      size: formData?.size,
      quantity: totalQuantity,
    };
    Cookies.set("customise_size", JSON.stringify(newItem), { expires: 7 });
    navigate(`/product-customise/${varient?.id}`);
  };
  const recentlyViewed = async () => {
    let cartItems = [];

    const existingCart = Cookies.get("recently_viewed");
    if (existingCart) {
      cartItems = JSON.parse(existingCart);
    }

    const existingItemIndex = cartItems.findIndex(
      (item) =>
        item.product_id === varient?.product?.id &&
        item.variant_id === varient?.id
    );

    if (existingItemIndex !== -1) {
      cartItems.splice(existingItemIndex, 1);
    }
    const newItem = {
      product_id: varient?.product?.id,
      variant_id: varient?.id,
    };

    cartItems.push(newItem);
    Cookies.set("recently_viewed", JSON.stringify(cartItems), { expires: 7 });
  };

  useEffect(() => {
    if (varient?.product?.id) {
      recentlyViewed(varient);
    }
  }, [varient]);

  const [reviews, setReviews] = useState([]);
  const getProductReview = async () => {
    const res = await allProductReviewGet(varient?.product?.id);
    if (res?.success) {
      setReviews(res?.data);
    }
  };

  const [userreview, setUserReviews] = useState(false);
  const getUserReview = async () => {
    const res = await productReviewGet(varient?.product?.id);

    setUserReviews(res?.data?.reviews);
  };

  useEffect(() => {
    getProductReview();
    getUserReview();
  }, [varient?.product]);

  const handelImage = async (image) => {
    setReviewshow(true);
    setImageshow(image);
  };

  const cartCookie = useCookie("coinz_kart_cart");

  const cartGet = async () => {
    const res = await getCart();
    if (res?.success) {
      setCart(res);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  const withoutCart = async () => {
    if (Cookies.get("coinz_kart_cart")) {
      await setCartWithout(JSON.parse(Cookies.get("coinz_kart_cart")));
      // updateCartCount();
    }
  };

  useEffect(() => {
    if (signin) {
      cartGet();
    } else {
      withoutCart();
    }
  }, [cartCookie, signin]);

  const handleInputChange = (index, quantity) => {
    const updatedFormData = { ...formData };

    if (!Array.isArray(updatedFormData.size)) {
      updatedFormData.size = [];
    }
    updatedFormData.size[index] = {
      ...updatedFormData.size[index],
      quantity: parseInt(quantity, 10),
    };
    setFormData(updatedFormData);
  };

  const handleInputInputChange = (value) => {
    setTotalQuantity(value);
  };
  const handleAcceptCookies = () => {
    setShowCookiesModal(false);
  };

  const breadcrumbItems = [
    { text: "HOME", link: "/home", className: "active" },
    {

      text: type || subcategorytype || " ",
      link: (() => {
        if (type === "Promotional Products") {
          return "/promotional-product";
        } else if (type === "Sustainable Products") {
          return "/sustainable-product";
        } else if (type === "Customize Product") {
          return "/customizable-product";
        } else if (type === "Wishlists") {
          return "/wishlist";
        } else if (subcategorytype) {
          // Handle subcategory type when subcategoryType is present
          return `/emp-satifaction/${varient?.product?.sub_category_id}`;
        } else {
          return " ";
        }
      })(),
      className: "active",
    },
    {
      text: varient?.product?.name,
      // link: "#",
      className: "active",
    },
  ];

  console.log("signin:", signin);
  console.log("customise_status:", varient?.product?.product_detail?.customize_status);

  return (
    <>
      <section className="ProductInner">
        <div className="container">
          <Breadcrumbs items={breadcrumbItems} />
          <div className="row mt-5">
            <Col xxl={6} xl={6} lg={6} md={7}>
              <div className="row  camera-sec">
                <Col xl={3} lg={3} md={3} className="verticalSlider">
                  <div>
                    <div className=" camera-slider">
                      <div className="row p-0 m-0">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <Swiper
                            slidesPerView={"auto"}
                            modules={[Autoplay]}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                            direction="vertical"
                            className="mySwiper"
                            breakpoints={{
                              0: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                              },
                              370: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                              },
                              485: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                              },
                              575: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                              },

                              768: {
                                slidesPerView: 6,
                                spaceBetween: 20,
                              },
                              992: {
                                slidesPerView: 6,
                                spaceBetween: 30,
                              },
                              1024: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                              },
                              1200: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                              },
                              1440: {
                                slidesPerView: 5,
                                spaceBetween: 30,
                              },
                              2000: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                              },
                            }}
                            navigation
                            pagination={{ clickable: true }}
                          >
                            {varient?.product_images?.map((slide, index) => (
                              <SwiperSlide key={index}>
                                <div className="Main-div">
                                  <div
                                    className="camera-imgs"
                                    onClick={() =>
                                      handleImageClick(IMG_URL + slide?.image)
                                    }
                                  >
                                    <img
                                      src={IMG_URL + slide?.image}
                                      className="inner-img"
                                    />
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl={9} lg={9} md={9}>
                  <div className="card-sec ">
                    <Heart_Component
                      product_id={varient?.product?.id}
                      variant_id={varient?.id}
                    />
                    <div className="product-detail text-center">
                      {selectedImage ? (
                        <div ref={zoomableRef}>
                          <img
                            src={selectedImage}
                            className="zoomable__img"
                            alt="Selected"
                          />
                        </div>
                      ) : (
                        <div className="specs" ref={zoomableRef}>
                          <img
                            src={IMG_URL + varient?.product_images[0]?.image}
                            className="zoomable__img"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>

                <div className="horizantalSlider">
                  <div className=" camera-slider">
                    <div className="row p-0 m-0">
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Swiper
                          slidesPerView={"auto"}
                          modules={[Autoplay]}
                          onSwiper={(swiper) => setSwiperInstance(swiper)}
                          breakpoints={{
                            0: {
                              slidesPerView: 3,
                              spaceBetween: 10,
                            },
                            370: {
                              slidesPerView: 4,
                              spaceBetween: 10,
                            },
                            485: {
                              slidesPerView: 4,
                              spaceBetween: 10,
                            },
                            575: {
                              slidesPerView: 4,
                              spaceBetween: 30,
                            },

                            768: {
                              slidesPerView: 4,
                              spaceBetween: 20,
                            },
                            992: {
                              slidesPerView: 6,
                              spaceBetween: 30,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 30,
                            },
                            1200: {
                              slidesPerView: 4,
                              spaceBetween: 30,
                            },
                            1440: {
                              slidesPerView: 5,
                              spaceBetween: 30,
                            },
                            2000: {
                              slidesPerView: 5,
                              spaceBetween: 20,
                            },
                          }}
                          navigation
                          pagination={{ clickable: true }}
                          className="mySwiper"
                        >
                          {images?.map((slide, index) => (
                            <SwiperSlide key={index}>
                              <div className="Main-div">
                                <div
                                  className="camera-imgs"
                                  onClick={() => handleImageClick(slide?.image)}
                                >
                                  <img
                                    src={slide?.image}
                                    className="inner-img"
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Product Review */}
              <div className="Productmaindesktop">
                <div className="ProductReview">
                  <h3 className="reviewHeading">
                    Product Review ({reviews?.length})
                  </h3>
                  <div className="allreviews">
                    {reviews && reviews.length > 0 ? (
                      reviews.map((val) => (
                        <div className="row" key={val.id}>
                          {" "}
                          {/* Added key for list rendering */}
                          <Col xl={9} lg={9} md={9} xs={9}>
                            <div className="reviewHead">
                              <p className="reviewName">
                                {val?.user?.first_name} {val?.user?.last_name} (
                                <FontAwesomeIcon icon="fa-solid fa-star" />{" "}
                                {val?.ratings})
                              </p>
                              <p>{val?.review_message}</p>

                              <div className="helpReportbtnsec">
                                <Button className="btn helpful">
                                  Helpful?
                                </Button>
                                <p>Report</p>
                              </div>
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} xs={3}>
                            <div className="reviewImgmain">
                              <img
                                src={
                                  IMG_URL +
                                  val?.product_reviews_images[0]?.image
                                }
                                className="reviewImg"
                              />
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assest/image/Careers/playIcon.png"
                                }
                                className="playIconimg"
                                onClick={() =>
                                  handelImage(
                                    IMG_URL +
                                    val?.product_reviews_images[0]?.image
                                  )
                                }
                              />
                            </div>
                          </Col>
                        </div>
                      ))
                    ) : (
                      <p className="no-data">Review not available</p>
                    )}
                  </div>
                </div>

                <BestMatched category={varient?.product?.category_id} />

                <BestSellers subcategory={varient?.product?.sub_category_id} />
                <RecentlyViewed />
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={5}>
              <Row className=" productInfo justify-content-center">
                <Col lg={9} xs={12} md={12}>
                  <Card className="mb-4 productInfocard">
                    <Card.Body>
                      <div className="productInfohead">
                        {/* <h3>{varient?.product?.name}</h3> */}
                        <h3>{varient?.product?.name
                          .split(' ')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}</h3>

                      </div>
                      <Card.Subtitle className="mb-2 text-muted">
                        ₹ {varient?.s_price}
                      </Card.Subtitle>
                      <Card.Text>
                        <div className="startpointsmain">
                          {varient?.averageRating > 0 ? (<span>⭐ {varient?.averageRating}</span>) : null}
                          <br />
                          {/* <span><img src={process.env.PUBLIC_URL + "/assest/image/Careers/circleStar.png"} className='circleStar' />You will earn 39 Points for purchasing this product.</span> */}
                        </div>
                        {varient?.unit <= 0 ? (
                          <span style={{ color: "red" }}>Out of Stock</span>
                        ) : (
                          <span style={{ color: "green" }}>In Stock</span>
                        )}
                      </Card.Text>

                      <div className="ColourMain">
                        <h5>Variants</h5>
                        <div className="colourSubhead">
                          <h3>Colours</h3>
                          <p>{varient?.v_name}</p>
                        </div>

                        <div className="colourSection">
                          {productdata?.map((val, index) => (
                            <div className="productImgcolour">
                              <img
                                src={IMG_URL + val?.product_images[0]?.image}
                                onClick={() => getSinglevarient(val?.id)}
                              />
                            </div>
                          ))}

                        </div>
                      </div>

                      <div>
                        <div className="productSubhead">
                          <h3 className="mt-2">Choose Size & Quantity:</h3>
                          <div className="sizeChartMain">
                            {varient?.product_cloth_sizes?.map(
                              (item, index) => (
                                <div key={index} className="sizechart">
                                  <Form.Control
                                    className="chartinfo mb-0"
                                    type="number"
                                    placeholder="N/A"
                                    min="0"
                                    value={
                                      formData?.size &&
                                      formData?.size[index] &&
                                      formData?.size[index].quantity
                                    }
                                    onFocus={(e) => {
                                      if (e.target.value === "0") {
                                        e.target.value = "";
                                      }
                                    }}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      value = value.replace(/^0+/, "");
                                      value = Math.max(0, parseFloat(value));

                                      handleInputChange(index, value);
                                    }}
                                  // onChange={(e) => {

                                  //     const value = Math.max(0, parseFloat(e.target.value) || 0); // Ensure the value is not negative
                                  //     handleInputChange(index, value);
                                  // }}
                                  />
                                  <p style={{ color: `${item.color}` }}>
                                    {item.cloth_size?.name}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                          {error && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error}
                            </div>
                          )}

                        </div>

                        <div className="totalQuantitysec">
                          <Form.Group
                            controlId="formTotalQuantity"
                            className="formQuantity"
                          >
                            <Form.Control
                              min="0"
                              className="chartinfo mb-0"
                              type="number"
                              value={totalQuantity}
                              disabled={sizeInput}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "-" ||
                                  e.key === "e" ||
                                  e.key === "+"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) =>
                                handleInputInputChange(
                                  e.target.value.replace(/^0+/, "")
                                )
                              }
                            // onChange={(e) => handleInputInputChange(e.target.value)}
                            />
                          </Form.Group>

                          <p>₹ {totalQuantity * varient?.s_price}</p>
                        </div>

                        <div className="quantityTable">
                          <Row>
                            <Col xl={6} xs={6}>
                              <div className="quantitymain">
                                <p>Quantity</p>
                              </div>
                            </Col>
                            <Col xl={6} xs={6}>
                              <div className="quantitymain text-end">
                                <p>Price Per Unit</p>
                              </div>
                            </Col>
                          </Row>
                          {varient?.product_quantities?.map((item, index) => (
                            <Row key={index} className="quantitySize">
                              <Col xl={3} lg={3} xs={3}>
                                <p>
                                  {item?.min}-{item?.max}
                                </p>
                              </Col>
                              <Col xl={6} lg={6} xs={6} className="my-auto">
                                <div className="productdashedline"></div>
                              </Col>
                              <Col xl={3} lg={3} xs={3}>
                                <p
                                  className={
                                    item.unit === "Get a Quote"
                                      ? "getUnderline"
                                      : "removeUnderline"
                                  }
                                >
                                  ₹{item.unit}
                                </p>
                              </Col>
                            </Row>
                          ))}
                          <a
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                            href={`/request-quotation`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>Get a Quote</p>
                           
                          </a>
                          <h3>Country of Origin : {varient?.product?.country?.name}</h3>
                        </div>

                        <div className="customisebtn">
                          {/* {signin ? (
                            varient?.varient?.product?.product_detail?.customize_status === 1 ? (
                              <Button
                                type="button"
                                className="btn"
                                onClick={customisecart}
                              >
                                customise
                              </Button>
                            ) : null
                          ) : (
                            <>
                            
                              You will have to login for customise.
                              <Button type="button" className="btn" disabled>
                                customise
                              </Button>
                            </>
                          )} */}
                          {/* {signin && varient?.product?.product_detail?.customize_status === 1 &&
                            (
                              <Button
                                type="button"
                                className="btn"
                                onClick={customisecart}
                              >
                                customise
                              </Button>
                            )}

                          {!signin && varient?.product?.product_detail?.customize_status === 1 &&
                            (
                              <>
                                You will have to login for customise.
                                <Button type="button" className="btn" disabled>
                                  customise
                                </Button>
                              </>
                            )} */}

                          {signin && varient?.product?.product_detail?.customize_status === 1 && (
                            <>
                              {varient?.unit > 0 ? (
                                <Button
                                  type="button"
                                  className="btn"
                                  onClick={customisecart}
                                >
                                  Customise
                                </Button>
                              ) : (
                                <></>
                                // <Heart_Component_Button
                                //   product_id={varient?.product?.id}
                                //   variant_id={varient?.id}
                                // />
                                // <span style={{ color: "red" }}>Out of Stock</span>
                              )}
                            </>
                          )}

                          {!signin && varient?.product?.product_detail?.customize_status === 1 && (
                            <>
                              {varient?.unit > 0 ? (
                                <>
                                  You will have to login to customize.
                                  <Button type="button" className="btn" disabled>
                                    customise
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {/* <span style={{ color: "red" }}>Out of Stock</span>
                                  <Button type="button" className="btn" disabled>
                                    customise
                                  </Button> */}
                                </>
                              )}
                            </>
                          )}


                          {signin && varient?.product?.product_detail?.customize_status === 1 && totalQuantity < 24 && (
                            <>
                              {varient?.unit > 0 ? (
                                <p>*Minimum 24 pcs. needed for Customisation</p>
                              ) : (
                                <></>
                              )}
                            </>
                          )}

                          {varient?.unit > 0 ? (
                            <Button
                              type="button"
                              className="btn"
                              onClick={createAddTocart}
                            >
                              Add to Cart
                            </Button>
                          ) : (
                            <span style={{ color: "red" }}>Out of Stock</span>
                            // <Heart_Component_Button
                            //   product_id={varient?.product?.id}
                            //   variant_id={varient?.id}
                            // />
                          )}





                        </div>

                        <div className="accordionProdInfo">
                          <Accordion>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                PRODUCT DESCRIPTION
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="accordionContent">
                                  <p>
                                    {typeof varient?.product?.product_detail
                                      ?.about === "string"
                                      ? parse(
                                        varient?.product?.product_detail
                                          ?.about
                                      )
                                      : ""}
                                  </p>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>PRODUCT CARE</Accordion.Header>
                              <Accordion.Body>
                                <div className="accordionContent">
                                  <p>
                                    {typeof varient?.product?.product_detail
                                      ?.benifits === "string"
                                      ? parse(
                                        varient?.product?.product_detail
                                          ?.benifits
                                      )
                                      : ""}
                                  </p>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                DELIVERY & RETURNS
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="accordionContent">
                                  <p>
                                    {typeof varient?.product?.product_detail
                                      ?.storage === "string"
                                      ? parse(
                                        varient?.product?.product_detail
                                          ?.storage
                                      )
                                      : ""}
                                  </p>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>GIFTING</Accordion.Header>
                              <Accordion.Body>
                                <div className="accordionContent">
                                  <p>{varient?.product?.short_description}</p>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>

                        <div className="belowHEading">
                          <p className="mb-1">
                            Product Reference: H457740HA1OLA{" "}
                          </p>
                          <p>Like To Know More? Contact Customer Service</p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>

            <div className="Productmainmobile">
              <div className="ProductReview">
                <h3 className="reviewHeading">Product Review (5)</h3>
                <div className="allreviews">
                  <div className="row">
                    <Col xl={9} lg={9} md={9} xs={9}>
                      <div className="reviewHead">
                        <p className="reviewName">
                          Shubham Kumar (
                          <FontAwesomeIcon icon="fa-solid fa-star" /> 4)
                        </p>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing &
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>

                        <div className="helpReportbtnsec">
                          <Button className="btn helpful">Helpful?</Button>
                          <p>Report</p>
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} xs={3}>
                      <div className="reviewImgmain">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/image/AllProducts/reviewImg.png"
                          }
                          className="reviewImg"
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/image/Careers/playIcon.png"
                          }
                          className="playIconimg"
                          onClick={() => setReviewshow(true)}
                        />
                      </div>
                    </Col>
                  </div>

                  <div className="row">
                    <Col xl={9} lg={9} md={9} xs={9}>
                      <div className="reviewHead">
                        <p className="reviewName">
                          Shubham Kumar (
                          <FontAwesomeIcon icon="fa-solid fa-star" /> 4)
                        </p>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing &
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>

                        <div className="helpReportbtnsec">
                          <Button className="btn helpful">Helpful?</Button>
                          <p>Report</p>
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} xs={3}>
                      <div className="reviewImgmain">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/image/AllProducts/reviewImg.png"
                          }
                          className="reviewImg"
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/image/Careers/playIcon.png"
                          }
                          className="playIconimg"
                          onClick={() => setReviewshow(true)}
                        />
                      </div>
                    </Col>
                  </div>
                </div>
              </div>

              <BestMatched />

              <BestSellers />
              <RecentlyViewed />
            </div>
            <ReviewVideomodal
              image={Imageshow}
              show={reviewshow}
              onHide={() => setReviewshow(false)}
            />
            {!isCookieAccepted && (
              <Cookies_popup_modal
                isCookieAccepted={isCookieAccepted}
                setIsCookieAccepted={setIsCookieAccepted}
                show={showCookiesModal}
                onHide={() => setShowCookiesModal(false)}
                onAcceptCookies={handleAcceptCookies}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetails;

