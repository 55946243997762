import "./banner/Banner.css";
import React, { useEffect, useRef, useState, useContext } from "react";
import Banner from "./banner/Banner";
import WeExpress from "./WeExpress/WeExpress";
import AllCategory from "./AllCategory/AllCategory";
import ShopByOccasion from "./ShopByOccasion/ShopByOccasion";
import PromotionalProduct from "./PromotionalProduct/PromotionalProduct";
import SustainableProduct from "./SustainableProduct/SustainableProduct";
import HomeAbout from "./HomeAbout/HomeAbout";
import SavingOffer from "./SavingOffer/SavingOffer";
import Customerlove from "./Customerlove/Customerlove";
import ArticlesInpiration from "./ArticlesInpiration/ArticlesInpiration";
import Mob_search from "./mobsearch/Mob_search";
import { Banners,Gratitude ,allCategory,CustomerLove} from "../../utils/apis/master/Master";
import { occasion ,promotional_products,sustainable_products,HomeOffer} from '../../utils/apis/Home/Homeapi';
import { Counter } from "../../utils/apis/AboutUs/AboutUs";
import { blog } from "../../utils/apis/Blog/Blog";
function Home() {
  const [dataBanner, setBannerData] = useState();
 
  const getAllBannerData = async () => {
    const res = await Banners();
    if (res?.success) {
      setBannerData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  const [dataGratitude, setDataGratitude] = useState();
  const getAllDataGratitude = async () => {
      const res = await Gratitude();
      if (res?.success) {
          setDataGratitude(res?.data);
      } else {
          console.error('Error fetching  data:', res?.error);
      }
  }

  const [dataCategory, setDataCategory] = useState();
    const getAllDataCategory = async () => {
        const res = await allCategory(2);
        if (res?.success) {
          setDataCategory(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const [occasionprod, setOcassionprod] = useState([]);
    const getOcassion = async () => {
        const res = await occasion();
        if (res?.success) {
            setOcassionprod(res?.data)
        }
    }

    const [promoProduct, setPromoproduct] = useState([]);
    const getPromotional = async () => {
        const res = await promotional_products();
        if (res?.success) {
            setPromoproduct(res?.data)
        }
    }

    const [sustainProduct, setSustainproduct] = useState([]);
    const getSustainable = async () => {
        const res = await sustainable_products();
        if (res?.success) {
            setSustainproduct(res?.data)
        }
    }

    const [dataCounter, setDataCounter] = useState();
   
    const getAllDataCounter = async () => {
        const res = await Counter();
        if (res?.success) {
          setDataCounter(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const [dataHomeOffer, setDataHomeOffer] = useState();
    const getAllDataHomeOffer = async () => {
      const res = await HomeOffer();
      if (res?.success) {
          setDataHomeOffer(res?.data);
      } else {
          console.error('Error fetching  data:', res?.error);
      }
  }

  const [dataCustomerLove, setDataCustomerLove] = useState();
  const getAllDataCustomerLove = async () => {
    const res = await CustomerLove();
    if (res?.success) {
        setDataCustomerLove(res?.data);
    } else {
        console.error('Error fetching  data:', res?.error);
    }
}
const [datablog, setDatablog] = useState();
const getAllDatablog = async () => {
  const res = await blog();
  if (res?.success) {
      setDatablog(res?.data);
  } else {
      console.error('Error fetching  data:', res?.error);
  }
}
console.log(datablog,'datablog datablog datablog bbbbbbbbbbbb');

  useEffect(() => {
    getAllDatablog();
    getAllDataCustomerLove();
    getAllDataHomeOffer();
    getAllDataCounter();
    getSustainable();
    getPromotional();
    getOcassion();
    getAllDataGratitude();
    getAllBannerData();
    getAllDataCategory();
  }, []);
  return (
    <>
      {/* <Mob_search /> */}

      {dataBanner?.length > 0 && <Banner dataBanner={dataBanner} />}
      {dataCategory?.length > 0 && <AllCategory data={dataCategory} />}
      {dataGratitude?.length > 0 && <WeExpress data={dataGratitude} />}
      {occasionprod?.length > 0 && <ShopByOccasion occasionprod={occasionprod} />}
      {promoProduct?.length > 0 && <PromotionalProduct promoProduct={promoProduct} />}
      {sustainProduct?.length > 0 && <SustainableProduct sustainProduct={sustainProduct} />}
      {dataCounter && <HomeAbout data={dataCounter} />}
      {dataHomeOffer?.length > 0 && <SavingOffer data={dataHomeOffer} />}
      {dataCustomerLove?.length > 0 && <Customerlove data={dataCustomerLove} />}
      {datablog?.data?.length > 0 && <ArticlesInpiration data={datablog} />}
  
    </>
  );
}

export default Home;
