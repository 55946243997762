import React, { useEffect, useRef, useState, useContext } from "react";
import "./Customerlove.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Rating } from "react-simple-star-rating";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Context } from "../../../utils/context";
import { CustomerLove } from "../../../utils/apis/master/Master";
const Customerlove = ({ data }) => {
  const [rating, setRating] = useState(0);
  const handleRating = (rate: number) => {
    setRating(rate);
  };
  const customerdata = [
    { id: 1, name: "Shekhar Rao" },
    { id: 1, name: "Shekhar Rao" },
    { id: 1, name: "Shekhar Rao" },
  ];

  const { IMG_URL } = useContext(Context);

  return (
    <>
      <section className="CustomerLove">
        <div className="container">
          <div className="customerHead">
            <h3>Customers <b className="boldtext">Love</b> Us</h3>
            <p>Voices Of Delight: Our Customers Share Their Stories!</p>
          </div>
          <div className="customer-love-slider">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((val, index) => (
                <>
                  <SwiperSlide>
                    <div className="card customercard">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                            <div className="imgMainsec">
                              <img src={IMG_URL + val?.image} />
                            </div>
                          </div>
                          <div className="col-lg-9 col-md-9 col-sm-9 col-9">
                            <div className="cardContent">
                              <Rating
                                readonly
                                initialValue={Number(val?.ratings)}
                                size={20}
                              />
                              <p>{val?.sub_name}</p>

                              <h3>{val?.name} </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Customerlove;
