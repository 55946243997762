import React, { useEffect, useRef, useState, useContext } from "react";
import "./ReturnOrder.css";
import { Button } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import Returnmodal from "../Common-Component/Commonmodal/Returnmodal/Returnmodal";
import Ordercancel from "../Common-Component/Commonmodal/Ordercancel/Ordercancel";
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import { Link } from "react-router-dom";
import { blogDetails, blog } from "../../utils/apis/Blog/Blog";
import { Context } from "../../utils/context";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import ReviewModal from "../Common-Component/Commonmodal/ReviewModal/ReviewModal";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  getOrder,
  getSingleOrder,
} from "../../utils/apis/orderProcess/orderProcess";
const ReturnOrder = () => {
  const [rating, setRating] = useState(0);
  const [showReturn, setShowreturn] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

  const items = [
    { id: 1, text: "PROFILE" },
    { id: 1, text: "Order Product" },
  ];
  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    formatDate,
  } = useContext(Context);
  const [data, setData] = useState();
  const { id } = useParams();
  const { IMG_URL } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const getAllData = async () => {
    const res = await getSingleOrder(id);
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      ratings: 1,
    },
  });
  const [ratings, setRatings] = useState([1, 0, 0, 0, 0]);
  const handleStarClick = (starIndex) => {
    const newRatings = [...ratings];
    var rate = 1;
    for (let i = 0; i < newRatings.length; i++) {
      newRatings[i] = i < starIndex ? 1 : 0;
    }
    newRatings?.map((value, index) => {
      if (value == 1) {
        rate = index + 1;
      }
    });

    setRatings(newRatings);
    getValues("ratings");
    setValue("ratings", rate);
  };

  return (
    <>
      <section className="ReturOrder">
        <div className="container">
          <Breadcrumbs items={items} />
          <div className="row mt-5">
            <div className="col-lg-3">
              <div className="ProductImgsec">
                <img
                  src={
                    IMG_URL + data?.product_variant?.product_images[0]?.image
                  }
                  className="returnproductImg"
                />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="returnOrderdetails">
                <div className="row ProductTitle">
                  <div className="col-lg-6">
                    <div className="productHead">
                      <h3>{data?.product?.name}</h3>
                    </div>
                  </div>
                  <div className="col-lg-6 text-end">
                    <div className="viewproductDetailsbtn">
                      <Link to={`/productDetails/${data?.product?.id}`}>
                        <Button className="btn" type="button">
                          View Product Details
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="row ProductTitle">
                  <div className="col-lg-6">
                    <div className="productHead">
                      <h3>Order Number: {data?.invoice_number}</h3>
                      <p>Payment : {data?.order?.payment_mode}</p>
                      {/* <p>Rate a Product : <span className='ms-3'><Rating onClick={handleRating} initialValue={Number(data?.order?.user?.product_review?.ratings)} size={20} /></span> </p> */}
                    </div>
                  </div>
                  <div className="col-lg-6 text-end">
                    <div className="return-cancel-btn">
                      {data?.order_status_id == 4 ? (
                        <Button
                          className="btn me-3"
                          type="button"
                          onClick={() => setShowreturn(true)}
                        >
                          Return Order
                        </Button>
                      ) : data?.order_status_id !== 4 &&
                        data?.order_status_id !== 5 &&
                        data?.order_status_id !== 6 ? (
                        <Button
                          className="btn"
                          type="button"
                          onClick={() => setShowCancel(true)}
                        >
                          Cancel Order
                        </Button>
                      ) : (
                        <div> </div>
                      )}

                      {/* <Button className='btn me-3' type='button' disabled={data?.order_status_id !== 4} onClick={() => setShowreturn(true)}>Return Order</Button>
                                            <Button className='btn' type='button' disabled={data?.order_status_id !== 1} onClick={() => setShowCancel(true)}>Cancel Order</Button> */}
                    </div>
                  </div>
                </div>

                <div className="OrderTracking">
                  <h3>Order Tracking</h3>
                  {data?.order_status_id === 6 ? (
                    // <div className='TrackingProcess'>

                    //     <div className='Order'>
                    //         <div>
                    //         <div className='orderIconImg'>
                    //             <div className='orderIcon orderIcon-incomplete'>
                    //                 <img className='status-img status-img-incompleted' src={process.env.PUBLIC_URL + "/assest/image/ReutnOrder/Icon.png"} />

                    //             </div>
                    //             <div className='orderplacehead'>
                    //                 <h3>Return Requested
                    //                 </h3>

                    //             </div>
                    //         </div>
                    //         <p>{data?.order_histories[0].order_status?.id === 1 && formatDate(data?.order_histories[0]?.createdAt)}</p>
                    //     </div>
                    //     </div>
                    //     <div className='dottedLine dottedLine-completed' > </div>
                    //     <div className='Order'>
                    //         <div>
                    //         <div className='orderIconImg'>
                    //             <div className='orderIcon orderIcon-incomplete'>
                    //                 <img className='status-img status-img-incompleted' src={process.env.PUBLIC_URL + "/assest/image/ReutnOrder/Icon.png"} />

                    //             </div>
                    //             <div className='orderplacehead'>
                    //                 <h3>Return Approved</h3>

                    //             </div>
                    //         </div>
                    //         <p>{data?.order_histories[0].order_status?.id === 3 && formatDate(data?.order_histories[0]?.createdAt)}</p>
                    //     </div>
                    //     </div>
                    //     <div className='dottedLine dottedLine-completed' > </div>
                    //     <div className='Order'>
                    //         <div>
                    //         <div className='orderIconImg'>
                    //             <div className='orderIcon orderIcon-incomplete'>
                    //                 <img className='status-img status-img-incompleted' src={process.env.PUBLIC_URL + "/assest/image/ReutnOrder/Icon.png"} />

                    //             </div>
                    //             <div className='orderplacehead'>
                    //                 <h3>Product Picked</h3>

                    //             </div>
                    //         </div>
                    //         <p>{data?.order_histories[0].order_status?.id === 3 && formatDate(data?.order_histories[0]?.createdAt)}</p>
                    //     </div>
                    //     </div>
                    //     <div className='dottedLine dottedLine-completed' > </div>

                    //     <div className='Order'>
                    //         <div>
                    //         <div className='orderIconImg'>
                    //             <div className='orderIcon orderIcon-incomplete'>
                    //                 <img className='status-img status-img-incompleted' src={process.env.PUBLIC_URL + "/assest/image/ReutnOrder/Icon.png"} />

                    //             </div>
                    //             <div className='orderplacehead'>
                    //                 <h3>Refund Process</h3>

                    //             </div>
                    //         </div>
                    //         <p>{data?.order_histories[0].order_status?.id === 5 && formatDate(data?.order_histories[0]?.createdAt)}</p>
                    //     </div>
                    //     </div>

                    // </div>

                    <div className="TrackingProcess">
                      <div className="Order">
                        <div>
                          {data?.return_order?.order_return_histories?.find(
                            (history) => history.return_status?.id === 1
                          ) ? (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-complete">
                                <img
                                  className="status-img status-img-completed"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Return Requested</h3>
                              </div>
                            </div>
                          ) : (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-incomplete">
                                <img
                                  className="status-img status-img-incompleted"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Return Requested</h3>
                              </div>
                            </div>
                          )}
                          <p>
                            {data?.return_order?.order_return_histories?.find(
                              (history) => history.return_status?.id === 1
                            )
                              ? formatDate(
                                  data?.return_order?.order_return_histories?.find(
                                    (history) => history.return_status?.id === 1
                                  )?.createdAt
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="dottedLine dottedLine-completed"> </div>
                      <div className="Order">
                        <div>
                          {data?.return_order?.order_return_histories?.find(
                            (history) => history.return_status?.id === 2
                          ) ? (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-complete">
                                <img
                                  className="status-img status-img-completed"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Return Approved</h3>
                              </div>
                            </div>
                          ) : (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-incomplete">
                                <img
                                  className="status-img status-img-incompleted"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Return Approved</h3>
                              </div>
                            </div>
                          )}
                          <p>
                            {data?.return_order?.order_return_histories?.find(
                              (history) => history.return_status?.id === 2
                            )
                              ? formatDate(
                                  data?.return_order?.order_return_histories?.find(
                                    (history) => history.return_status?.id === 2
                                  )?.createdAt
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="dottedLine dottedLine-completed"> </div>
                      <div className="Order">
                        <div>
                          {data?.return_order?.order_return_histories?.find(
                            (history) => history.return_status?.id === 3
                          ) ? (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-complete">
                                <img
                                  className="status-img status-img-completed"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Product Picked</h3>
                              </div>
                            </div>
                          ) : (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-incomplete">
                                <img
                                  className="status-img status-img-incompleted"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Product Picked</h3>
                              </div>
                            </div>
                          )}
                          <p>
                            {data?.return_order?.order_return_histories?.find(
                              (history) => history.return_status?.id === 3
                            )
                              ? formatDate(
                                  data?.return_order?.order_return_histories?.find(
                                    (history) => history.return_status?.id === 3
                                  )?.createdAt
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="dottedLine dottedLine-completed"> </div>
                      <div className="Order">
                        <div>
                          {data?.return_order?.order_return_histories?.find(
                            (history) => history.return_status?.id === 3
                          ) ? (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-complete">
                                <img
                                  className="status-img status-img-completed"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Refund Process</h3>
                              </div>
                            </div>
                          ) : (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-incomplete">
                                <img
                                  className="status-img status-img-incompleted"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Refund Process</h3>
                              </div>
                            </div>
                          )}
                          <p>
                            {data?.return_order?.order_return_histories?.find(
                              (history) => history.return_status?.id === 3
                            )
                              ? formatDate(
                                  data?.return_order?.order_return_histories?.find(
                                    (history) => history.return_status?.id === 3
                                  )?.createdAt
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="TrackingProcess">
                      <div className="Order">
                        <div>
                          {data?.order_histories?.find(
                            (history) => history.order_status?.id === 1
                          ) ? (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-complete">
                                <img
                                  className="status-img status-img-completed"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Order Placed</h3>
                              </div>
                            </div>
                          ) : (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-incomplete">
                                <img
                                  className="status-img status-img-incompleted"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Order Placed</h3>
                              </div>
                            </div>
                          )}
                          <p>
                            {data?.order_histories?.find(
                              (history) => history.order_status?.id === 1
                            )
                              ? formatDate(
                                  data?.order_histories?.find(
                                    (history) => history.order_status?.id === 1
                                  )?.createdAt
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="dottedLine dottedLine-completed"> </div>
                      <div className="Order">
                        <div>
                          {data?.order_histories?.find(
                            (history) => history.order_status?.id === 2
                          ) ? (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-complete">
                                <img
                                  className="status-img status-img-completed"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Processing</h3>
                              </div>
                            </div>
                          ) : (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-incomplete">
                                <img
                                  className="status-img status-img-incompleted"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Processing</h3>
                              </div>
                            </div>
                          )}
                          <p>
                            {data?.order_histories?.find(
                              (history) => history.order_status?.id === 2
                            )
                              ? formatDate(
                                  data?.order_histories?.find(
                                    (history) => history.order_status?.id === 2
                                  )?.createdAt
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="dottedLine dottedLine-completed"> </div>
                      <div className="Order">
                        <div>
                          {data?.order_histories?.find(
                            (history) => history.order_status?.id === 3
                          ) ? (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-complete">
                                <img
                                  className="status-img status-img-completed"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Shipping</h3>
                              </div>
                            </div>
                          ) : (
                            <div className="orderIconImg">
                              <div className="orderIcon orderIcon-incomplete">
                                <img
                                  className="status-img status-img-incompleted"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/image/ReutnOrder/Icon.png"
                                  }
                                />
                              </div>
                              <div className="orderplacehead">
                                <h3>Shipping</h3>
                              </div>
                            </div>
                          )}
                          <p>
                            {data?.order_histories?.find(
                              (history) => history.order_status?.id === 3
                            )
                              ? formatDate(
                                  data?.order_histories?.find(
                                    (history) => history.order_status?.id === 3
                                  )?.createdAt
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="dottedLine dottedLine-completed"> </div>
                      {data?.order_status_id == 5 ? (
                        <div className="Order">
                          <div>
                            {data?.order_histories?.find(
                              (history) => history.order_status?.id === 5
                            ) ? (
                              <div className="orderIconImg">
                                <div className="orderIcon orderIcon-complete">
                                  <img
                                    className="status-img status-img-completed"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assest/image/ReutnOrder/Icon.png"
                                    }
                                  />
                                </div>
                                <div className="orderplacehead">
                                  <h3>Cancel Order</h3>
                                </div>
                              </div>
                            ) : (
                              <div className="orderIconImg">
                                <div className="orderIcon orderIcon-incomplete">
                                  <img
                                    className="status-img status-img-incompleted"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assest/image/ReutnOrder/Icon.png"
                                    }
                                  />
                                </div>
                                <div className="orderplacehead">
                                  <h3>Order Placed</h3>
                                </div>
                              </div>
                            )}
                            <p>
                              {data?.order_histories?.find(
                                (history) => history.order_status?.id === 5
                              )
                                ? formatDate(
                                    data?.order_histories?.find(
                                      (history) =>
                                        history.order_status?.id === 5
                                    )?.createdAt
                                  )
                                : ""}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="Order">
                          <div>
                            {data?.order_histories?.find(
                              (history) => history.order_status?.id === 4
                            ) ? (
                              <div className="orderIconImg">
                                <div className="orderIcon orderIcon-complete">
                                  <img
                                    className="status-img status-img-completed"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assest/image/ReutnOrder/Icon.png"
                                    }
                                  />
                                </div>
                                <div className="orderplacehead">
                                  <h3>Delivered</h3>
                                </div>
                              </div>
                            ) : (
                              <div className="orderIconImg">
                                <div className="orderIcon orderIcon-incomplete">
                                  <img
                                    className="status-img status-img-incompleted"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assest/image/ReutnOrder/Icon.png"
                                    }
                                  />
                                </div>
                                <div className="orderplacehead">
                                  <h3>Delivery On</h3>
                                </div>
                              </div>
                            )}
                            <p>
                              {data?.order_histories?.find(
                                (history) => history.order_status?.id === 4
                              )
                                ? formatDate(
                                    data?.order_histories?.find(
                                      (history) =>
                                        history.order_status?.id === 4
                                    )?.createdAt
                                  )
                                : ""}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="OrderSummary">
                  <div className="OrderDetailshead">
                    <h3>Order Details</h3>
                  </div>

                  <div className="row summaryDetails">
                    <div className="col-xl-5 col-lg-6">
                      <div className="Pricedetailsmain">
                        <h3>Price Details :</h3>
                        <p>{data?.quantity} item</p>
                      </div>

                      <div className="Pricedetailsmain">
                        <h3>Total Price :</h3>
                        <p>₹ {data?.price}</p>
                      </div>
                      <div className="Pricedetailsmain">
                        <h3>Total Gift Wrap Price :</h3>
                        <p>₹ {parseFloat(data?.gift_wrap_price || 0)}</p>
                      </div>
                      <div className="Pricedetailsmain">
                        <h3>Total Customise Price :</h3>
                        <p>₹ {parseFloat(data?.customize_price || 0)}</p>
                      </div>
                      <div className="Pricedetailsmain">
                        <h3>Total Tax :</h3>
                        <p>₹ {parseFloat(data?.total_tax || 0)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="text-end grossPrice">
                    <p>
                      Gross Total Price : <span>₹ {data?.total_amount}</span>{" "}
                    </p>
                  </div>
                </div>

                <div className="Delivery_status">
                  <h3>Delivery Address</h3>
                  <p>
                    {data?.order?.user?.first_name}{" "}
                    {data?.order?.user?.last_name} (
                    {data?.order?.user?.contact_no})
                  </p>
                  <p>
                    {data?.order?.shipping_address?.address},
                    {data?.order?.shipping_address?.city?.name},
                    {data?.order?.shipping_address?.state?.name},
                    {data?.order?.shipping_address?.country?.name},
                    {data?.order?.shipping_address?.pincode?.name}
                  </p>
                </div>

                <div className="Delivery_status">
                  <h3>Support</h3>
                  <p>Coinzkart</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReviewModal
        product_id={data?.product_id}
        rating={getValues("ratings")}
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
      />
      <Returnmodal
        getAllData={getAllData}
        show={showReturn}
        onHide={() => setShowreturn(false)}
        orderId={data?.id}
      />
      <Ordercancel
        getAllData={getAllData}
        show={showCancel}
        onHide={() => setShowCancel(false)}
        orderId={data?.id}
      />
    </>
  );
};

export default ReturnOrder;
