import React, { useState, useEffect, useContext } from "react";
import "./Register.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Form, Button, InputGroup, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import SellerStepform from "./SellerStepform/SellerStepform";
import { userRegister } from "../../utils/apis/login/Login";
import Cookies from "js-cookie";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import { loginCart } from "../../utils/apis/addToCart/addToCart";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../utils/context";
import PasswordChecklist from "react-password-checklist";
import SuccessfullyModal from "../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import Cookies_popup_modal from "../Common-Component/Commonmodal/Cookies_popup_modal/Cookies_popup_modal";

const Register = () => {
  // const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const navigate = useNavigate();

  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEye);
  const [iconTwo, setIconTwo] = useState(faEye);
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);
  const [showSuccess, setShowsuccess] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConformPasswordVisibility = () => {
    setShowConformPassword(!showConformPassword);
  };

  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    isCookieAccepted,
    setIsCookieAccepted,IMG_URL,setSigninGoogle,
  } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();
  const customiseClose = () => {
    setShowsuccess(true);
    setTimeout(() => {
      setShowsuccess(false);
      navigate(-2);
      // window.location.pathname = "/home"
    }, 3000);
  };
  const [showCookiesModal, setShowCookiesModal] = useState(true);

  console.log("errors",errors)

  const onSubmit = async (data) => {
    if (!isCookieAccepted) {
      setShowCookiesModal(true);
      return;
    }

    setShimmerLoader(true);
    if (password === passwordAgain) {
      data.term_condition_status = isChecked;
      const res = await userRegister(data);
      if (res?.success) {
        await Cookies.set("coinz_kart_security", res?.data?.token);
        await setSignin(true);
        await loginCart();
        customiseClose();
        setTimeout(() => {
            setShimmerLoader(false);
            navigate(-2);
        }, 1000);

      } else {
        setShimmerLoader(false);
        if (res?.message?.both) {
          setError("email", { message: res?.message?.both });
          setError("contact_no", { message: "" });
        }
        if (res?.message?.email) {
          setError("email", { message: res?.message?.email });
        }
        if (res?.message?.contact_no) {
          setError("contact_no", { message: res?.message?.contact_no });
        }
      }
    } else {
      setError("re_password", { message: "Password Does not match" });
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleEmailOrContactChange = (event) => {
    setValue("contact_no", event.target.value);
  };
  const handleAcceptCookies = () => {
    // Additional logic for accepting cookies (e.g., storing consent)
    setShowCookiesModal(false);
  };

  function googleLogin() {
    const backendURL = IMG_URL;
    const path = "/api/auth/google"; // Dynamic path based on condition or variable
  
    // Dynamically construct the full URL
    const dynamicURL = `${backendURL}${path}`;
  
    // Open the URL in the current window
    window.open(dynamicURL, "_self"); // "_self" opens in the same tab
    setSigninGoogle(true);
  }
  return (
    <div className="login-container">
      <div className="register-box">
        <div className="logo-register">
          <img
            src={process.env.PUBLIC_URL + "/assest/image/logo/header-logo.png"}
            className="logo"
          />
          <Link to={"/"}>
            <p className="backtohome">Back to Home</p>
          </Link>
        </div>
        <Row className="justify-content-center me-0 ms-0">
          <Col xxl={7} xl={8} md={10}>
            <div className="login-header">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          Create User Account
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          Create Seller Account
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  </div>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="first" className="tablogin">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group controlId="formFirstName">
                        <Row>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Form.Select
                                className="form-control"
                                aria-label="Default select example"
                                placeholder="Select Prefix"
                                {...register("prefix", {
                                  required: "Prefix is required",
                                })}
                              >
                                <option value="">Select Prefix</option>
                                <option value="Ms">Ms</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Mr">Mr</option>
                              </Form.Select>
                              {errors?.prefix && (
                                <sup className="text-danger">
                                  {errors.prefix.message}
                                </sup>
                              )}
                              {/* {errors.prefix && <sup className="text-danger">{errors.prefix.message}</sup>} */}
                            </div>
                          </Col>
                          <Col lg={9}>
                            <Form.Group controlId="formFirstName">
                              <Form.Label className="emailtxt">
                                First Name*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="First_Name"
                                placeholder="Enter First Name"
                                {...register("first_name", {
                                  required: "First name required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.first_name,
                                  "is-valid": getValues("first_name"),
                                })}
                                onKeyDown={(event) => {
                                  if (!RegxExpression.name.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Group>
                            <Form.Group controlId="formMiddleName">
                              <Form.Label className="emailtxt">
                                Middle Name / Initial
                              </Form.Label>
                              <div className="formfieldregister">
                                <Form.Control
                                  type="text"
                                  name="mid_name"
                                  placeholder="Enter First Name"
                                  {...register("mid_name", {
                                    // required: " Middle Name required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.mid_name,
                                    "is-valid": getValues("mid_name"),
                                  })}
                                  onKeyDown={(event) => {
                                    if (!RegxExpression.name.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </Form.Group>

                            <Form.Group controlId="formFirstName">
                              <Form.Label className="emailtxt">
                                Last Name*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="last_name"
                                placeholder="Enter Last Name"
                                {...register("last_name", {
                                  required: "Last Name required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.last_name,
                                  "is-valid": getValues("last_name"),
                                })}
                                onKeyDown={(event) => {
                                  if (!RegxExpression.name.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Group>

                            <Form.Group controlId="formFirstName">
                              <Form.Label className="emailtxt">
                                Email*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="Enter Email"
                                {...register("email", {
                                  validate: (value) =>
                                    value || getValues("contact_no")
                                      ? true
                                      : "Email or Mobile Number is required",
                                  pattern: {
                                    value: RegxExpression?.email,
                                    message: "Invalid email address",
                                  },
                                })}
                                // {...register("email", {
                                //     required: "Email is required",
                                //     pattern: {
                                //         value: RegxExpression.email,
                                //         message: "Invalid email address",
                                //     },
                                // })}
                                className={classNames("", {
                                  "is-invalid": errors?.email,
                                  "is-valid": getValues("email"),
                                })}
                              />
                              {errors?.email && (
                                <sup className="text-danger">
                                  {errors?.email?.message}
                                </sup>
                              )}
                              <div className="checkMain">
                                <Controller
                                  name="newsletter"
                                  control={control}
                                  defaultValue={0}
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      label="Sign Up for Newsletter"
                                      className="checkNews"
                                      onChange={(e) =>
                                        field.onChange(e.target.checked ? 1 : 0)
                                      }
                                    />
                                  )}
                                />
                              </div>
                            </Form.Group>

                            <Form.Group controlId="formFirstName">
                              <Form.Label className="emailtxt">
                                Password*
                              </Form.Label>
                              <div className="formfieldregister">
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Enter Password"
                                  name="password"
                                  {...register("password", {
                                    required: "Password is required",
                                    minLength: {
                                      value: 8,
                                      message:
                                        "Password must be at least 8 characters long",
                                    },
                                    pattern: {
                                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                      message:
                                        "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                    },
                                  })}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                {errors?.password && (
                                  <sup className="text-danger">
                                    {errors?.password?.message}
                                  </sup>
                                )}

                                <div
                                  className="eyeIcon"
                                  onClick={togglePasswordVisibility}
                                >
                                  {showPassword ? "🙈" : "👁️"}
                                </div>
                              </div>
                            </Form.Group>

                            <Form.Group controlId="formFirstName">
                              <Form.Label className="emailtxt">
                                Confirm Password*
                              </Form.Label>
                              <div className="formfieldregister">
                                <Form.Control
                                  type={
                                    showConformPassword ? "text" : "password"
                                  }
                                  placeholder="Re-Enter Password"
                                  name="re_password"
                                  {...register("re_password", {
                                    required: "Re-Password is required",
                                  })}
                                  onChange={(e) =>
                                    setPasswordAgain(e.target.value)
                                  }
                                />
                                {errors?.re_password && (
                                  <sup className="text-danger">
                                    {errors?.re_password?.message}
                                  </sup>
                                )}
                                {password && (
                                  <PasswordChecklist
                                    rules={[
                                      "minLength",
                                      "specialChar",
                                      "number",
                                      "capital",
                                      "match",
                                    ]}
                                    minLength={8}
                                    value={password}
                                    valueAgain={passwordAgain}
                                    onChange={(isValid) => { }}
                                    style={{
                                      fontSize: "10px",
                                      padding: "4px",
                                      margin: "0",
                                    }}
                                  />
                                )}

                                <div
                                  className="eyeIcon"
                                  onClick={toggleConformPasswordVisibility}
                                >
                                  {showConformPassword ? "🙈" : "👁️"}
                                </div>
                              </div>
                            </Form.Group>

                            <div className="mb-3">
                              <Form.Label className="emailtxt">
                                Contact*
                              </Form.Label>
                              <Controller
                                name="contact_no"
                                control={control}
                                rules={{
                                  validate: (value) =>
                                    value || getValues("email")
                                      ? true
                                      : "Mobile Number or Email is required",
                                  pattern: {
                                    value: /^\+?\d{1,4}[-\s]?\d{10}$/,
                                    message:
                                      "Invalid number format. Please enter a valid number with an optional country code",
                                  },
                                }}
                               
                                render={({ field }) => (
                                  <PhoneInput
                                    country={"in"}
                                    {...field}
                                    className={classNames("", {
                                      "is-invalid": errors?.contact_no,
                                      "is-valid": getValues("contact_no"),
                                    })}
                                    onChange={(value) => field.onChange(value)}
                                    type="text"
                                    placeholder="Enter Mobile Number"
                                    onKeyDown={(event) => {
                                      if (
                                        !RegxExpression.phone.test(event.key)
                                      ) {
                                        if (event.key !== "Backspace") {
                                          event.preventDefault();
                                        }
                                      }
                                    }}
                                    maxLength={10}
                                  />
                                )}
                              />
                              {errors?.contact_no && (
                                <sup className="text-danger">
                                  {errors?.contact_no?.message}
                                </sup>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>

                      <div className="text-center">
                        {/* <Link to={"/login"}> */}
                        <Button
                          className="createaccountbtn mt-md-4 mt-3"
                          type="submit"
                        >
                          Create Account
                        </Button>

                        {/* </Link> */}
                      </div>
                    </Form>
                    <div className="signup-link">
                      <p>
                        {/* <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        /> */}
                        <input
                          type="checkbox"
                          {...register("isChecked", {
                            required: "Term And Conditions Required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.isChecked,
                          })}
                        />

                        By signing up, I agree to the{" "}
                        <Link to={"/terms-condition"}>
                          <span style={{ color: "#234C9E" }}>
                            Terms and Conditions
                          </span>
                        </Link>
                      </p>
                      {errors?.isChecked && (
                        <sup className="text-danger">
                          {errors?.isChecked?.message}
                        </sup>
                      )}
                      <p>
                        Already have an account?{" "}
                        <Link to={"/login"}>
                          <span
                            style={{
                              color: "#234C9E",
                              fontWeight: "bold",
                              textDecoration: "underline",
                            }}
                          >
                            Login
                          </span>
                        </Link>
                      </p>
                    </div>
                    <div className="social-login">
                      <p>You can also SignUp by using your</p>
                      <div  onClick={googleLogin} className="social-icons">
                        {/* <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/image/login/facebook.png"
                          }
                          alt="Google"
                        /> */}
                        <img
                          src={process.env.PUBLIC_URL + "/assest/image/login/google.png"}
                          alt="Google"
                         
                          style={{ cursor: 'pointer' }}
                        /> Login with Google
                        {/* <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/image/login/twitter.png"
                          }
                          alt="Google"
                        /> */}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <SellerStepform />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
        <div className="blueOverlay"></div>
      </div>
      {!isCookieAccepted && (
        <Cookies_popup_modal
          isCookieAccepted={isCookieAccepted}
          setIsCookieAccepted={setIsCookieAccepted}
          show={showCookiesModal}
          onHide={() => setShowCookiesModal(false)}
          onAcceptCookies={handleAcceptCookies}
        />
      )}
      <SuccessfullyModal
        show={showSuccess}
        onHide={() => setShowsuccess(false)}
        text={"Your Request has been send successfully"}
      />
    </div>
  );
};

export default Register;
