import React, { useEffect, useRef, useState, useContext } from 'react'
import "./AllCategories.css";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';
import { getCategoryProduct, getAllCategoryProduct } from "../../utils/apis/Product/Product";
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";
import { Context } from "../../utils/context";
import EmptyAnimation from '../EmptyAnimation/EmptyAnimation';
const AllCategories = () => {
    const products = [
        { id: 1, title: "Trophy", text: "₹. 00.00/-", imgSrc: "/assest/image/AllProducts/ProductImg1.png" },
        { id: 2, title: "Trophy", text: "₹. 00.00/-", imgSrc: "/assest/image/AllProducts/ProductImg1.png" },
        { id: 3, title: "Cotton Polo T-Shirt", text: "₹. 00.00/-", imgSrc: "/assest/image/AllProducts/ProductImg2.png" },
        { id: 4, title: "Ceramic Mugs", text: "₹. 00.00/-", imgSrc: "/assest/image/AllProducts/ProductImg3.png" },
        { id: 5, title: "Trophy", text: "₹. 00.00/-", imgSrc: "/assest/image/AllProducts/ProductImg1.png" },
    ];

    const items = [
        { id: 1, text: "HOME", link: "/home" },
        { id: 1, text: "CATEGORIES" },
    ]
    const [data, setData] = useState();
    const { id } = useParams();
    const { IMG_URL } = useContext(Context);
    const getAllData = async () => {
        const res = await getCategoryProduct(id);
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const getAllcategoryData = async () => {
        const res = await getAllCategoryProduct();
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        if (id) {
            getAllData();
        } else {
            getAllcategoryData();
        }

    }, [id]);


    return (
        <>
            <section className='AllCategory'>
                <div className='container'>
                    {id ? (
                        <div className='categoryDesktop'>
                            <Breadcrumbs items={items} />
                            <div className='categoryHead'>
                                <h3>{data?.name}</h3>
                            </div>

                            {data ? (
                                data?.sub_categories?.length > 0 ? (
                                    data.sub_categories.map((val, index) => (
                                        <div key={index} className='card categoryCard'>
                                            <h3>{val?.name}</h3>

                                            <div className='viewMore'>
                                                <Link to={`/emp-satifaction/${val?.id}`}>
                                                    <p>View More</p>
                                                </Link>
                                            </div>
                                            <div className='row appreciationMain'>
                                                {val?.products.length > 0 ? (
                                                    val.products.map(product => (

                                                        <Col key={product.id} xl={2} lg={3} md={4} sm={6}>
                                                            <Link to={`/productDetails/${product?.id}?subcategorytype=${val?.name}`} >
                                                                <Card className='appreciationCard'>
                                                                    <div className='p-2'>
                                                                        <Card.Img variant="top" src={IMG_URL + product?.product_variants[0]?.product_images?.[0]?.image} />
                                                                    </div>
                                                                    <Card.Body>
                                                                        <Card.Title>{product?.product_variants[0]?.v_name}</Card.Title>
                                                                        <Card.Text>
                                                                            ₹ {product?.product_variants[0]?.s_price}
                                                                        </Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Link>
                                                        </Col>

                                                    ))
                                                ) : (
                                                    <p className='no-data-found-text'>No products available</p>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className='no-data-found-text'>No categories available</p>
                                )
                            ) : (
                                <>
                                    <EmptyAnimation />
                                    <p className='no-data-found-text'>No products available</p>
                                </>
                            )}

                        </div>
                    ) : (
                        <div className='categoryDesktop'>
                            <Breadcrumbs items={items} />
                            <div className='categoryHead'>
                                <h3>Category</h3>
                            </div>


                            {data ? (
                                data?.length > 0 ? (
                                    data.map((val, index) => (
                                        <div key={index} className='card categoryCard'>
                                            <h3>{val?.name}</h3>

                                            <div className='viewMore'>
                                                <Link to={`/emp-satifaction/category/${val?.id}`}>
                                                    <p>View More</p>
                                                </Link>
                                            </div>
                                            <div className='row appreciationMain'>
                                                {val?.products.length > 0 ? (
                                                    val.products.map(product => (

                                                        <Col key={product.id} xl={2} lg={3} md={4} sm={6}>
                                                            <Link to={`/productDetails/${product?.id}?subcategorytype=${product?.sub_category?.name}`} >
                                                                <Card className='appreciationCard'>
                                                                    <div className='p-2'>
                                                                        <Card.Img variant="top" src={IMG_URL + product?.product_variants[0]?.product_images?.[0]?.image} />
                                                                    </div>
                                                                    <Card.Body>
                                                                        <Card.Title>{product?.name}</Card.Title>
                                                                        <Card.Text>
                                                                            ₹{product?.product_variants[0]?.s_price}
                                                                        </Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Link>
                                                        </Col>

                                                    ))
                                                ) : (
                                                    <p className='no-data-found-text'>No products available</p>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className='no-data-found-text'>No categories available</p>
                                )
                            ) : (
                                <>
                                    <EmptyAnimation />
                                    <p className='no-data-found-text'>No products available</p>
                                </>
                            )}



                        </div>

                    )}

                    {id ? (

                        <div className='categoryMobile'>
                            <div className='mobiletabs'>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="0">

                                    <Nav variant="pills" className="flex-row">
                                        {data?.sub_categories?.map((val, index) => (
                                            <Nav.Item>
                                                <Nav.Link eventKey={index}>{val?.name}</Nav.Link>
                                            </Nav.Item>))}
                                      

                                    </Nav>

                                    <Tab.Content>
                                        {data ? (
                                            data?.sub_categories?.length > 0 ? (
                                                data?.sub_categories?.map((val, index) => (
                                                    <Tab.Pane eventKey={index}>
                                                        {val?.products.length > 0 ? (
                                                            val.products.map(product => (
                                                                <div className='categoryCard'>
                                                                    <Card className='appreciationCard'>
                                                                        <div className='p-2'>
                                                                            <Card.Img variant="top" src={IMG_URL + product?.product_variants[0]?.product_images?.[0]?.image} />
                                                                        </div>
                                                                        <Card.Body>
                                                                            <Card.Title>{product?.product_variants[0]?.v_name}</Card.Title>
                                                                            <Card.Text>
                                                                                ₹ {product?.product_variants[0]?.s_price}/-
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p className='no-data-found-text'>No products available</p>
                                                        )}
                                                    </Tab.Pane>
                                                ))
                                            ) : (
                                                <p className='no-data-found-text'>No categories available</p>
                                            )
                                        ) : (
                                            <>
                                                <EmptyAnimation />
                                                <p className='no-data-found-text'>No products available</p>
                                            </>
                                        )}
                                      
                                    </Tab.Content>

                                </Tab.Container>
                            </div>
                        </div>
                    ) : (
                        <div className='categoryMobile'>
                            <div className='mobiletabs'>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="0">

                                    <Nav variant="pills" className="flex-row">
                                        {data?.map((val, index) => (
                                            <Nav.Item>
                                                <Nav.Link eventKey={index}>{val?.name}</Nav.Link>
                                            </Nav.Item>))}
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="2">New Joining</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="3">Employee Personal Gifts</Nav.Link>
                                        </Nav.Item> */}

                                    </Nav>

                                    <Tab.Content>
                                        {data ? (
                                            data?.length > 0 ? (
                                                data?.map((val, index) => (
                                                    <Tab.Pane eventKey={index}>
                                                        {val?.products.length > 0 ? (
                                                            val.products.map(product => (
                                                                <div className='categoryCard'>
                                                                    <Card className='appreciationCard'>
                                                                        <div className='p-2'>
                                                                            <Card.Img variant="top" src={IMG_URL + product?.product_variants[0]?.product_images?.[0]?.image} />
                                                                        </div>
                                                                        <Card.Body>
                                                                            <Card.Title>{product?.name}</Card.Title>
                                                                            <Card.Text>
                                                                                ₹ {product?.product_variants[0]?.s_price}/-
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p className='no-data-found-text'>No products available</p>
                                                        )}
                                                    </Tab.Pane>
                                                ))
                                            ) : (
                                                <p className='no-data-found-text'>No categories available</p>
                                            )
                                        ) : (
                                            <>
                                                <EmptyAnimation />
                                                <p className='no-data-found-text'>No products available</p>
                                            </>
                                        )}
                                        {/* <Tab.Pane eventKey="2">
                                            <div className='categoryCard'>
                                                <Card className='appreciationCard'>
                                                    <div className='p-2'>
                                                        <Card.Img variant="top" src={process.env.PUBLIC_URL + "/assest/image/AllProducts/ProductImg1.png"} />
                                                    </div>
                                                    <Card.Body>
                                                        <Card.Title>Trophy</Card.Title>
                                                        <Card.Text>
                                                            ₹. 00.00/-
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="3">
                                            <div className='categoryCard'>
                                                <Card className='appreciationCard'>
                                                    <div className='p-2'>
                                                        <Card.Img variant="top" src={process.env.PUBLIC_URL + "/assest/image/AllProducts/ProductImg1.png"} />
                                                    </div>
                                                    <Card.Body>
                                                        <Card.Title>Trophy</Card.Title>
                                                        <Card.Text>
                                                            ₹. 00.00/-
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Tab.Pane> */}
                                    </Tab.Content>

                                </Tab.Container>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}

export default AllCategories