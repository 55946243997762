import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../utils/context";
import { useNavigate } from 'react-router-dom';
import { addWishlist } from "../../../utils/apis/Wishlist/Wishlist";
import "./Heart_Component.css";
import { Button } from "react-bootstrap";

function Heart_Component_Button({ variant_id, product_id }) {
  const [heartactive, setheartActive] = useState(false);
  const { shimmerLoader, setShimmerLoader, wishlistData, wishlistContext, signin } = useContext(Context);
  const navigate = useNavigate();

  const handleHeartClick = async (data) => {
    setShimmerLoader(true);
    if (signin) {
      const res = await addWishlist(data);
      if (res?.success) {
        wishlistContext();
      }
      setShimmerLoader(false);
    } else {
      setShimmerLoader(false);
      navigate('/login');
    }
  };

  useEffect(() => {
    wishlistContext();
  }, [signin]);

  return (
    <>
     <button  onClick={() => handleHeartClick({ product_id: product_id, variant_id: variant_id })} className="heart-button">
     Add To Wishlist
    </button>
      {/* <div className="hearticn">
        <Button
          variant="link"
          className="heart-button" // Add your custom class here for styling the button
          onClick={() => handleHeartClick({ product_id: product_id, variant_id: variant_id })}
        >
          <FontAwesomeIcon
            icon={faHeart}
            className={
              wishlistData?.some(item => item.product_id === product_id && item.variant_id === variant_id)
                ? "gobiheartss" // Active state
                : "gobiheart"   // Inactive state
            }
          />
        </Button>
      </div> */}
    </>
  );
}

export default Heart_Component_Button;
