import React, { useEffect, useRef, useState, useContext } from "react";
import "./ValueSection.css";
import { Context } from "../../../utils/context";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
const values = [
  {
    title: "Be Grateful",
    description:
      "We deeply appreciate our customers and focus on fostering trust and dependability.",
    image: "/assest/image/Aboutus/valueIcon1.png",
  },
  {
    title: "Be Innovative",
    description:
      "We constantly seek new and creative solutions to meet our customers' needs.",
    image: "/assest/image/Aboutus/valueIcon2.png",
  },
  {
    title: "Be Collaborative",
    description:
      "We believe in the power of teamwork and strive to build strong partnerships.",
    image: "/assest/image/Aboutus/valueIcon3.png",
  },
  {
    title: "Be Passionate",
    description:
      "We are passionate about our work and committed to excellence.",
    image: "/assest/image/Aboutus/valueIcon4.png",
  },
];

const ValuesSection = ({data}) => {
 
  const { IMG_URL } = useContext(Context);
 

  
  return (
    <div className="container">
      <div className="valueStandby">
        <div className="valueStandbyHead">
          <h3>
            The<b className="boldtext"> values</b> we stand by
          </h3>
        </div>
        <div className="row">
          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 30,
              },

              321: {
                slidesPerView: 1,
                spaceBetween: 30,
              },

              485: {
                slidesPerView: 2,
                spaceBetween: 30,
              },

              576: {
                slidesPerView: 2,
                spaceBetween: 30,
              },

              765: {
                slidesPerView: 2,
                spaceBetween: 30,
              },

              992: {
                slidesPerView: 3,
                spaceBetween: 30,
              },

              1024: {
                slidesPerView: 2,
                spaceBetween: 30,
              },

              1200: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            autoplay={{
              delay: 2000, // 3 seconds delay between slides
              disableOnInteraction: false, // allows autoplay to continue after interactions
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper valueStandby-swiper"
          >
            {data?.length > 0 ? (
              data.map((value, index) => (
                <SwiperSlide key={index}>
                  <div className="card valuestandCard">
                    <div className="cardImgsec">
                      <img src={IMG_URL + value?.image} alt={value.name} />
                    </div>
                    <div className="cardHead">
                      <h3>{value?.name}</h3>
                      <p>{value?.description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <div className="no-data">
                <p
                  style={{ textAlign: "center", fontWeight: "bold", margin: 0 }}
                >
                  No data available
                </p>
              </div>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default ValuesSection;
