import React, { useEffect, useRef, useState, useContext } from "react";
import "./Banner.css";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import Talktous from "../../Common-Component/Offcanvas/Talktous/Talktous";
import { Context } from "../../../utils/context";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";

function Banner({dataBanner}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { IMG_URL } = useContext(Context);
 
 
  return (
    <section className="home-banner">
      <div className="container-fluid ">
        <div className="row p-0">
          <div className="col-md-12 p-0">
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              autoplay={{
                delay: 5000, // 3 seconds delay between slides
                disableOnInteraction: false, // allows autoplay to continue after interactions
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper pb-5"
            >
              {dataBanner?.map((val)=>
              <SwiperSlide>
                <div className="image-holder p-0">
                  {/* <img className='ban-image' src={process.env.PUBLIC_URL + '/assest/image/home/banner.png'} alt="Logo" /> */}
                  <img
                    className="ban-image"
                    src={IMG_URL + val?.image}
                    alt="Logo"
                  />
                  <div className="bannerOverlay"></div>
                  <div className="overlay-color"></div>
                  <div className="content-holder">
                    <h1>
                      The <span>One-Stop</span>  Shop For All <br /> Your <span>Corporate Gifting</span>
                    </h1>
                    {/* <p>{val?.name}</p> */}
                    <div className="button-holder">
                      <Common_Button
                        text="TALK TO US"
                        className="talk-us"
                        onClick={handleShow}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              )}

              {/* <SwiperSlide>
                <div className="image-holder p-0">
               
                  <img
                    className="ban-image"
                    src={IMG_URL + data?.image}
                    alt="Logo"
                  />
                  <div className="bannerOverlay"></div>
                  <div className="overlay-color"></div>
                  <div className="content-holder">
                    <h1>
                      The One-Stop Shop For All <br /> Your Corporate Gifting
                    </h1>
                    <p>{data?.name}</p>
                    <div className="button-holder">
                      <Common_Button
                        text="TALK TO US"
                        className="talk-us"
                        onClick={handleShow}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="image-holder p-0">
                
                  <img
                    className="ban-image"
                    src={IMG_URL + data?.image}
                    alt="Logo"
                  />
                  <div className="bannerOverlay"></div>
                  <div className="overlay-color"></div>
                  <div className="content-holder">
                    <h1>
                      The One-Stop Shop For All <br /> Your Corporate Gifting
                    </h1>
                    <p>{data?.name}</p>
                    <div className="button-holder">
                      <Common_Button
                        text="TALK TO US"
                        className="talk-us"
                        onClick={handleShow}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="image-holder p-0">
               
                  <img
                    className="ban-image"
                    src={IMG_URL + data?.image}
                    alt="Logo"
                  />
                  <div className="bannerOverlay"></div>
                  <div className="overlay-color"></div>
                  <div className="content-holder">
                    <h1>
                      The One-Stop Shop For All <br /> Your Corporate Gifting
                    </h1>
                    <p>{data?.name}</p>
                    <div className="button-holder">
                      <Common_Button
                        text="TALK TO US"
                        className="talk-us"
                        onClick={handleShow}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
            </Swiper>
          </div>
        </div>
      </div>

      <Talktous
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        placement="end"
        name="end"
      />
    </section>
  );
}

export default Banner;
